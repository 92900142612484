<script setup>

import { Head, router, useForm } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";

const props = defineProps({
    resource: Object,
    actions: {
        type: Array,
        default: () => []
    },
});

const resource = props.resource;

const form = useForm({
    title: resource.title,
    gds_code: resource.gds_code,
    exit: false,
})

const handleInput = (id, key) => {
    form[key] = id;
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .put('/admin/meals/update/' + resource.id , {
            preserveScroll: true,
            // preserveState: false,
            onSuccess: () => {
                router.reload({ only: ['form'] }) // при сохранении без выходя обновляем данные в форме
            },
        })
}

const deleteItem = () => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/meals/destroy/' + resource.id, {
            preserveScroll: true,
        })
    }
}

</script>

<template>

    <Head title="Edit meal" />
    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/meals">Meals</a>
                </li>
                <li class="breadcrumb-item">
                    Edit meal
                </li>
            </ol>
        </template>

        <template v-slot:title>Meals</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">

                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Edit meal</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6">
                                        <input-simple
                                            label="Meal name"
                                            name="title"
                                            required
                                            placeholder="Enter meal name"
                                            v-model="form.title"
                                            :hint="form.errors.title"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <input-simple
                                            label="GDS"
                                            name="gds_code"
                                            required
                                            placeholder="Enter GDS code"
                                            v-model="form.gds_code"
                                            :hint="form.errors.gds_code"
                                            uppercase
                                        ></input-simple>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Delete')||actions.includes('Update')">
            <button type="button"
                    v-if="actions.includes('Delete')"
                    class="btn btn-outline-danger mb-3 mb-sm-0" @click="deleteItem">Delete meal</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-alt-secondary mb-3 mb-sm-0 ms-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-primary ms-sm-3" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
