import { ref } from "vue";

class Table {
    currentDirection = ref({});
    serviceSortableColumn = (key, form) => {
        if (this.currentDirection[key]) {
            if (this.currentDirection[key] === 'desc') {
                this.currentDirection[key] = 'asc';
                form.direction = 'asc';
                form.column = key;
            } else {
                this.currentDirection[key] = '';
                form.direction = '';
                form.column = '';
            }
        } else {
            this.currentDirection[key] = 'desc';
            form.direction = 'desc';
            form.column = key;
        }
    }
}

export default Table;


// const currentDirection = ref({});
// const serviceSortableColumn = (key, form) => {
//     if (currentDirection.value[key]) {
//         if (currentDirection.value[key] === 'desc') {
//             currentDirection.value[key] = 'asc';
//             form.direction = 'asc';
//             form.column = key;
//         } else {
//             currentDirection.value[key] = '';
//             form.direction = '';
//             form.column = '';
//         }
//     } else {
//         currentDirection.value[key] = 'desc';
//         form.direction = 'desc';
//         form.column = key;
//     }
// }
//
// export default serviceSortableColumn;
