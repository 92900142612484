<script setup>

import { Head, useForm, Link } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";
import InputTags from "../../components/UI/InputTags.vue";
import Tabs from "../../components/UI/Tabs.vue";
import Tab from "../../components/UI/Tab.vue";
import DropzoneNew from "../../components/UI/DropZoneNew.vue";
import {computed, ref} from "vue";

const props = defineProps({
    currencies: Object,
    languages: Object,
});

const form = useForm({
    title: {},
    iso2: '',
    iso3: '',
    capital: '',
    capital_iata: '',
    dial_codes: '',
    currency_id: '',
    population: '',
    cities: '',
    image: '',
    exit: false,
})

const searchCurrencyString = ref('');

const searchCurrency = (value) => {
    searchCurrencyString.value = value;
}

const currencyList = computed(() => {
    return props.currencies.filter(currency => currency.title.toLowerCase().includes(searchCurrencyString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
}) ;

const findLanguage = (code, array) => {
    return array.find((item) => item.code == code).title;
}

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    form[key] = option;
}

const storeFiles = (files) => {
    form.image = files;
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            currency_id: data.currency_id && data.currency_id.id ? data.currency_id.id : data.currency_id,
            // image: data.image,
        }))
        .post('/admin/countries/store', {
        preserveScroll: true,
        onSuccess: () => form.reset(),
    })
}

const handleChangeTag = (tags) => {
    form.error_title = tags;
}

</script>

<template>

    <Head title="Add country" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <Link class="link-fx"
                          href="/admin/countries">
                        Countries
                    </Link>
                </li>
                <li class="breadcrumb-item">
                    Add country
                </li>
            </ol>
        </template>

        <template v-slot:title>Countries</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <Tabs>
                                <Tab title="Add country" :selected="true">
                                    <div class="row g-12">
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple
                                                label="Country name"
                                                name="title[en]"
                                                required
                                                placeholder="Enter city name"
                                                v-model="form.title.en"
                                                :hint="form.errors['title.en']"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Country ISO2"
                                                          required
                                                          name="iso2"
                                                          placeholder="Enter country ISO2"
                                                          v-model="form.iso2"
                                                          :hint="form.errors.iso2"
                                                          uppercase
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Country ISO3"
                                                          required
                                                          name="iso3"
                                                          placeholder="Enter country ISO3"
                                                          v-model="form.iso3"
                                                          :hint="form.errors.iso3"
                                                          uppercase
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Capital"
                                                          required
                                                          name="capital"
                                                          placeholder="Enter capital name"
                                                          v-model="form.capital"
                                                          :hint="form.errors.capital"
                                            ></input-simple>
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Capital IATA"
                                                          required
                                                          name="capital_iata"
                                                          placeholder="Enter capital IATA"
                                                          v-model="form.capital_iata"
                                                          :hint="form.errors.capital_iata"
                                                          uppercase
                                            ></input-simple>
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-tags label="Dial"
                                                        required
                                                        name="dial_codes"
                                                        :hint="form.errors.dial_codes"
                                                        v-model="form.dial_codes"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="Currency"
                                                           required
                                                           enableSearch
                                                           @select-option="(e)=>handleSelect(e,'currency_id')"
                                                           :value="form.currency_id"
                                                           :options="currencyList"
                                                           :hint="form.errors.currency_id"
                                                           @search="searchCurrency"
                                                           name="currency_id"
                                                           placeholder="Select currency"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Population"
                                                          required
                                                          name="population"
                                                          placeholder="Enter number of population"
                                                          v-model="form.population"
                                                          :hint="form.errors.population"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Cities"
                                                          disabled
                                                          name="cities"
                                                          placeholder="Number of cities"
                                                          v-model="form.cities"
                                                          :hint="form.errors.cities"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12">
                                            <dropzone-new
                                                label="Flag image (max 1 files)"
                                                required
                                                name="images"
                                                maxFiles="1"
                                                :hint="form.errors['image']"
                                                v-model="form.image"
                                                @imagesChanger="storeFiles"
                                            >
                                            </dropzone-new>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab title="Languages">
                                    <div class="row g-12">
                                        <template v-for="item in languages">
                                            <div class="col-12 col-sm-6" v-if="(item.code !== 'en')">
                                                <input-simple
                                                    label="Country name"
                                                    :lang="item.title"
                                                    :name="'title['+[item.code]+']'"
                                                    placeholder="Enter country name"
                                                    v-model="form.title[item.code]"
                                                ></input-simple>
                                            </div>
                                        </template>
                                    </div>
                                </Tab>
                            </Tabs>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <button type="button" class="btn btn-alt-secondary mb-3 mb-sm-0 me-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button" class="btn btn-primary" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
