<template>
    <div class="form-group"
        :class="localHint ? 'has-error' : '' "
    >
<!--        :class="hint ? 'error' : '' "-->
<!--        :class="{hint: 'error'}"-->
<!--        :class="[{hint: 'error'}, {}]">-->
        <label
            v-if="label"
            :for="name"
            class="form-label"
            :class="disabled ? 'disabled' : ''"
        >
            {{ label}}
            <span v-if="required" class="form-required text-danger">*</span>
            <span v-if="lang" class="form-badge">{{ lang }}</span>
        </label>
        <input class="form-control" required="required"
               :maxlength="maxlength"
               :name="name"
               :type="type"
               :value="modelValue"
               :id="name"
               :placeholder="placeholder"
               :class="type === 'number' && 'no-spin-buttons'"
               @input="updateInput"
               :required="required"
               :disabled="disabled"
        >
        <div class="form-hint" v-if="localHint">
            {{ localHint }}
        </div>
    </div>
</template>

<!--v-model.number="inputValue"-->
<!--@input="filterInput"-->
<!--data() {-->
<!--return {-->
<!--inputValue: ''-->
<!--};-->
<!--},-->

<!--methods: {-->
<!--filterInput(event) {-->
<!--//-->
<!--// this.modelValue = event.target.value.replace(/\D/g, '');-->
<!--this.inputValue = event.target.value.replace(/\D/g, '');-->

<!--// console.log('filterInput: ', this.inputValue);-->
<!--this.$emit('update:modelValue', this.inputValue);-->
<!--}-->
<!--}-->

<script>
export default {
    name: "InputSimple",
    props: {
        type: {
            type: String,
            default: "text",
        },
        label: {
            type: String,
        },
        lang: {
            type: String,
        },
        name: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        hint: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        maxlength: {
            type: Number,
            default: 255,
        },
        modelValue: {
            type: [String, Number],
            default: "",
        },
        uppercase: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // Initialize localHint with the value of hint prop
            localHint: this.hint
        }
    },
    methods: {
        updateInput(event) {
            if (this.uppercase) {
                this.$emit('update:modelValue', event.target.value.toUpperCase());
            } else {
                this.$emit('update:modelValue', event.target.value);
            }
            // Set hint to null when input is changed
            this.localHint = null;
        }
    },
    watch: {
        // watch the 'hint' prop
        hint(newVal) {
            // Update 'localHint' when 'hint' changes
            this.localHint = newVal;
        }
    },
    // mounted() {
    //     // this.isDisabled = this.disable;
    //     // console.log('isDisabled: ', this.isDisabled)
    // }
}
</script>

<style>
.no-spin-buttons {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    & input[type=number] {
        -moz-appearance: textfield;
    }
}

</style>
