<script setup>

import { ref, defineEmits } from "vue";

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: {
        type: String,
    },
    hint: {
        type: String,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
    },
    checked: {
        type: Boolean,
        default: false
    },
    option: {
        type: Boolean,
        default: false
    },
});

const emit = defineEmits(['changeCheckbox']);
const isActive = ref(props.option);

const toggleDropdown = (event) => {
    isActive.value = event.target.checked;
    emit('changeCheckbox', event.target.checked)
}

</script>

<template>
    <div class="form-group "
         :class="hint ? 'has-error' : '' "
    >
        <div
            class="form-check form-switch"
            :class="{active: isActive}"
        >
            <input
                class="form-check-input"
                type="checkbox"
                :id="name"
                :name="name"
                :checked="isActive"
                @change="toggleDropdown"
            >
            <label class="form-check-label"
                   :for="name"
            >
                {{ isActive ? 'Published' : 'Hidden' }}
            </label>
        </div>

        <div class="form-hint" v-if="hint">
            {{ hint }}
        </div>
    </div>
</template>

<style scoped>

</style>
