<script setup>

import { Head, useForm, Link } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";
import RadioButtonsYesNo from "../../components/UI/RadioButtonsYesNo.vue";

const props = defineProps({
    engineCategories: Object,
});

const engineCategories = props.engineCategories;

const form = useForm({
    title: '',
    iata: '',
    icao: '',
    capacity: '',
    engine_category_id: '',
    engine_quantity: '',
    wide_body: '',
    pressurized: '',
    exit: false,
})

const handleRadioButtonInput = (id, key) => {
    form[key] = id == 'true' ? true : false
}

const handleSelect = (option, key) => {
    form[key] = option;
}

const handleChangeTag = (tags) => {
    form.error_title = tags;
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            engine_category_id: data.engine_category_id && data.engine_category_id.id ? data.engine_category_id.id : data.engine_category_id,
        }))
        .post('/admin/aircraft/store', {
        preserveScroll: true,
        onSuccess: () => form.reset(),
    })
}

</script>

<template>

    <Head title="Add aircraft" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <Link class="link-fx"
                          href="/admin/aircraft">
                        Aircrafts
                    </Link>
                </li>
                <li class="breadcrumb-item">
                    Add aircraft
                </li>
            </ol>
        </template>

        <template v-slot:title>Aircrafts</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Add aircraft</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Aircraft name"
                                            name="title"
                                            required
                                            placeholder="Enter aircraft name"
                                            v-model="form.title"
                                            :hint="form.errors.title"
                                        ></input-simple>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="IATA"
                                            name="iata"
                                            required
                                            placeholder="Enter IATA"
                                            v-model="form.iata"
                                            :hint="form.errors.iata"
                                            uppercase
                                        ></input-simple>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="ICAO"
                                            name="icao"
                                            placeholder="Enter ICAO"
                                            v-model="form.icao"
                                            :hint="form.errors.icao"
                                            uppercase
                                        ></input-simple>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Capacity"
                                            name="capacity"
                                            placeholder="Passengers capacity"
                                            v-model="form.capacity"
                                            :hint="form.errors.capacity"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <select-custom label="Category"
                                                       @select-option="(e)=>handleSelect(e,'engine_category_id')"
                                                       :value="form.engine_category_id"
                                                       :options="engineCategories"
                                                       :hint="form.errors.engine_category_id"
                                                       name="engine_category_id"
                                                       placeholder="Select category"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Engines"
                                            name="engine_quantity"
                                            placeholder="Number of engines"
                                            v-model="form.engine_quantity"
                                            :hint="form.errors.engine_quantity"
                                        ></input-simple>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <radio-buttons-yes-no
                                            label="Wide body"
                                            name="wide_body"
                                            @changeRadio="(e)=>handleRadioButtonInput(e,'wide_body')"
                                            :hint="form.errors.wide_body"
                                        />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <radio-buttons-yes-no
                                            label="Pressurized"
                                            name="pressurized"
                                            @changeRadio="(e)=>handleRadioButtonInput(e,'pressurized')"
                                            :hint="form.errors.pressurized"
                                        />
                                    </div>

                                </div>
                            </div>

                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <button type="button" class="btn btn-alt-secondary mb-3 mb-sm-0 me-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button" class="btn btn-primary" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
