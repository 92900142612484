<script setup>

import { computed, ref } from 'vue';
import { Head, useForm, Link, router } from '@inertiajs/vue3'
import axios from "axios";

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";
import InputTags from "../../components/UI/InputTags.vue";
import InputTagsWithData from "../../components/UI/NearbyAirports.vue";
import Tabs from "../../components/UI/Tabs.vue";
import Tab from "../../components/UI/Tab.vue";
import NearbyAirports from "../../components/UI/NearbyAirports.vue";

const props = defineProps({
    cityType: Object,
    countries: Object,
    states: Object,
    errorTitle: String | Array,
    resource: Object,
    timezones: Object,
    languages: Object,
    actions: {
        type: Array,
        default: () => []
    },
});
const airports = [
    {
        id: 771,
        title: 'Andros Town International Airport',
        iata: 'ASD',
        type: 'airport',
        city: {
            id: 332,
            title: 'Andros Town',
            iata: 'ASD',
            type: 'city',
            country: {
                id: 178,
                title: 'Bahamas',
            },
        },
    },
    {
        id: 7832,
        title: 'Guasdualito Airport',
        iata: 'GDO',
        type: 'airport',
        city: {
            id: 3318,
            title: 'Guasdualito',
            iata: 'GDO',
            type: 'city',
            country: {
                id: 48,
                title: 'United States of Venezuela',
            },
        },
    },
    {
        id: 9229,
        title: 'JASDF Kenebetsu Airfield',
        iata: null,
        type: 'airport',
        city: null,
    },
    {
        id: 9230,
        title: 'JASDF Yakumo Airbase',
        iata: null,
        type: 'airport',
        city: null,
    },
    {
        id: 15120,
        title: 'PERDASDEFOGU',
        iata: null,
        type: 'airport',
        city: null,
    },
];
const fetchAirports = (value) => {
    console.log('value', value.target.value)
    if (!value) return; // Прекратить выполнение, если значение пусто

    axios.post('https://infohub.test/api/get/airports/iata-or-name', {
        iata: value.target.value
    })
        .then(response => {

            const res = response.data.data.map(item => ({
                text: item.iata || item.title,
                value: item.id
            }));
            this.formattedItems = res;
            console.log(this.formattedItems.values);
        })
        .catch(error => {
            console.error("Error fetching airports:", error);
            // Обработка ошибок, например, показать сообщение пользователю
        });
}

const cityTypeList = props.cityType;
const resource = props.resource;
const statesList = ref(props.states);
const timezonesList = ref(props.timezones);

const findLanguage = (code, array) => {
    return array.find((item) => item.code == code).title;
}

const form = useForm({
    nearby_airports: resource.nearby_airports,
    title: resource.title,
    iata: resource.iata,
    area_phone_code: resource.area_phone_code,
    city_type_id: resource.city_type,
    country_id: resource.country,
    state_id: resource.state,
    state_exist: !!resource?.state || statesList.value.length > 0,
    timezone_id: resource.timezone,
    latitude: resource.latitude,
    longitude: resource.longitude,
    altitude: resource.altitude,
    metro_area_name: resource.metro_area_name,
    metro_area_code: resource.metro_area_code,
    population: resource.population,
    error_title: props.errorTitle,
    resource_id: resource.id,
    exit: false,
})

const searchCountryString = ref('');

const selectRequest = (key, option) => {
    if (key === 'country_id') {
        statesList.value = [];
        timezonesList.value = [];
        form.state_id = '';
        form.timezone_id = '';
        axios
            .post('/admin/states/search/list', {
                country_id: option.id,
            })
            .then((response) => {
                if (response.data.data.length > 0) {
                    statesList.value = response.data.data;
                    form.state_exist = true;
                } else {
                    form.state_exist = false;
                }
            })

        axios
            .post('/admin/timezones/search/list', {
                country_id: option.id,
            })
            .then((response) => {
                if (response.data.data.length > 0) timezonesList.value = response.data.data;
            })
    }
}

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    form[key] = option;
    selectRequest(key, option);
}

const searchCountry = (value) => {
    searchCountryString.value = value;
}

const isCountrySelected = computed(() => !!form.country_id && statesList.value.length > 0)
const isStateSelected = computed(() => !!form.country_id)

const countriesList = computed(() => {
    return props.countries.filter(country => country.title.toLowerCase().includes(searchCountryString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
}) ;

const submitForm = (exit) => {
    console.log('form', form.nearby_airports);
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            city_type_id: data.city_type_id && data.city_type_id.id ? data.city_type_id.id : data.city_type_id,
            country_id: data.country_id && data.country_id.id ? data.country_id.id : data.country_id,
            state_id: data.state_id && data.state_id.id ? data.state_id.id : data.state_id,
            timezone_id: data.timezone_id && data.timezone_id.id ? data.timezone_id.id : data.timezone_id,
        }))
        .put('/admin/cities/update/' + resource.id, {
            preserveScroll: true,
            // preserveState: false,
            onSuccess: () => {
                router.reload({ only: ['form'] }) // при сохранении без выходя обновляем данные в форме
            },
    })
}

const deleteItem = () => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/cities/destroy/' + resource.id, {
            preserveScroll: true,
        })
    }
}

</script>

<template>

    <Head title="Edit city" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <Link class="link-fx"
                          href="/admin/cities">
                        Cities
                    </Link>
                </li>
                <li class="breadcrumb-item">
                    Edit city
                </li>
            </ol>
        </template>

        <template v-slot:title>Cities</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <Tabs>
                                <Tab title="Edit city" :selected="true">
                                    <div class="block-content block-content-full">
                                        <div class="row g-12">
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple
                                                    label="City name"
                                                    name="title[en]"
                                                    required
                                                    placeholder="Enter city name"
                                                    v-model="form.title.en"
                                                    :hint="form.errors['title.en']"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple label="City IATA"
                                                              required
                                                              name="iata"
                                                              placeholder="Enter IATA"
                                                              v-model="form.iata"
                                                              :hint="form.errors.iata"
                                                              uppercase
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <select-custom label="City type"
                                                               @select-option="(e)=>handleSelect(e,'city_type_id')"
                                                               :value="form.city_type_id"
                                                               :options="cityTypeList"
                                                               :hint="form.errors.city_type_id"
                                                               name="city_type_id"
                                                               placeholder="Select Country"
                                                ></select-custom>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <select-custom label="Country"
                                                               required
                                                               enableSearch
                                                               @select-option="(e)=>handleSelect(e,'country_id')"
                                                               :value="form.country_id"
                                                               :options="countriesList"
                                                               :hint="form.errors.country_id"
                                                               @search="searchCountry"
                                                               name="country_id"
                                                               placeholder="Select Country"
                                                ></select-custom>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <select-custom label="State"
                                                               :required="form.state_exist"
                                                               :disabled="!isCountrySelected"
                                                               @select-option="(e)=>handleSelect(e,'state_id')"
                                                               :value="form.state_id"
                                                               :options="statesList"
                                                               :hint="form.errors.state_id"
                                                               name="state_id"
                                                               placeholder="Select State"
                                                ></select-custom>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <select-custom label="Timezone"
                                                               :disabled="!isStateSelected"
                                                               @select-option="(e)=>handleSelect(e,'timezone_id')"
                                                               :value="form.timezone_id"
                                                               :options="timezonesList"
                                                               :hint="form.errors.timezone_id"
                                                               name="timezone_id"
                                                               placeholder="Select timezone"
                                                ></select-custom>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple label="Latitude"
                                                              name="latitude"
                                                              placeholder="Enter latitude"
                                                              v-model="form.latitude"
                                                              :hint="form.errors.latitude"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple label="Longitude"
                                                              name="longitude"
                                                              placeholder="Enter longitude"
                                                              v-model="form.longitude"
                                                              :hint="form.errors.longitude"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple label="Altitude, m"
                                                              name="altitude"
                                                              placeholder="Enter altitude"
                                                              v-model.number="form.altitude"
                                                              :hint="form.errors.altitude"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple label="Metro area name"
                                                              name="metro_area_name"
                                                              placeholder="Enter metro area name"
                                                              v-model="form.metro_area_name"
                                                              :hint="form.errors.metro_area_name"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple label="Metro area code"
                                                              name="metro_area_code"
                                                              placeholder="Enter metro area code"
                                                              v-model="form.metro_area_code"
                                                              :hint="form.errors.metro_area_code"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple label="Population"
                                                              name="population"
                                                              placeholder="Enter population"
                                                              v-model="form.population"
                                                              :hint="form.errors.population"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12 col-sm-6 col-lg-4">
                                                <input-simple label="Area Code"
                                                              name="phone_area_code"
                                                              placeholder="Enter area phone code"
                                                              v-model="form.area_phone_code"
                                                              :hint="form.errors.area_phone_code"
                                                ></input-simple>
                                            </div>
                                            <div class="col-12">
                                               <nearby-airports
                                                   v-model="form.nearby_airports"
                                                   :hint="form.errors.title"
                                                   label="Nearby Airports"
                                                   req
                                               />
                                            </div>
                                            <div class="col-12">
                                                <input-tags label="Syntax Errors"
                                                            name="error_title"
                                                            :hint="form.errors.title"
                                                            v-model="form.error_title"
                                                />
                                            </div>
                                        </div>
                                        <input class="form-control" name="delimiter" type="hidden">
                                    </div>
                                </Tab>
                                <Tab title="Languages">
                                    <div class="row g-12">
                                        <template v-for="(value, key) in form.title">
                                            <div class="col-12 col-sm-6" v-if="(value !== 'None') && (key !== 'en')">
                                                <input-simple
                                                    label="City name"
                                                    :lang="findLanguage(key, props.languages)"
                                                    :name="'title['+[key]+']'"
                                                    placeholder="Enter city name"
                                                    v-model="form.title[key]"
                                                ></input-simple>
                                            </div>
                                        </template>
                                    </div>
                                </Tab>
                            </Tabs>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Delete') || actions.includes('Update')">
            <button type="button"
                    v-if="actions.includes('Delete')"
                    class="btn btn-outline-danger mb-3 mb-sm-0" @click="deleteItem">Delete city</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-alt-secondary mb-3 mb-sm-0 ms-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-primary ms-sm-3" @click="submitForm(false)">Save</button>
        </template>

    </Layout>
</template>

<style>

</style>
