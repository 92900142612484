<script setup>

import { computed, ref } from "vue";
import {Head, useForm, Link, router} from '@inertiajs/vue3';

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";
import TextareaSimple from "../../components/UI/TextareaSimple.vue";
import Modal from "../../components/UI/Modal.vue";

const props = defineProps({
    gdsList: Object,
    pccTypes: Object,
    countries: Object,
    currencies: Object,
});

const pccList = props.pccTypes;

const form = useForm({
    title: '',
    pcc: [],
    pcc_exist: false,
    exit: false,
})

const formPCC = useForm({
    default_fare_type: '',
    pcc: '',
    bridge: '',
    gds_id: '',
    country_id: '',
    type_id: '',
    currency_id: '',
    // airports: [],
    // business_net_pub_issuing: '',
    // business_net_pub_ref_exch: '',
    // business_tours_issuing: '',
    // business_tours_ref_exch: '',
    // business_void: '',
    //
    // economy_net_pub_issuing: '',
    // economy_net_pub_ref_exch: '',
    // economy_tours_issuing: '',
    // economy_tours_ref_exch: '',
    // economy_void: '',
    //
    // premium_net_pub_issuing: '',
    // premium_net_pub_ref_exch: '',
    // premium_tours_issuing: '',
    // premium_tours_ref_exch: '',
    // premium_void: '',
    notes: '',
});

const searchCountryString = ref('');
const searchCurrencyString = ref('');

const searchCountry = (value) => {
    searchCountryString.value = value;
}

const searchCurrency = (value) => {
    searchCurrencyString.value = value;
}

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    formPCC[key] = option;
}

const showModal = ref(false);

const showModalChange = () => {
    showModal.value = !showModal.value;

    const body = document.querySelector('body');
    const scrollbarWidth = document.body.offsetWidth - document.body.clientWidth;

    if (showModal.value) {
        body.classList.add('modal__open');
        body.style.overflow = 'hidden';
        body.style.paddingRight = scrollbarWidth + 'px';
    } else {
        body.classList.remove('modal__open');
        body.removeAttribute('style');
    }

    const tooltips = document.querySelectorAll('.tooltip');
    tooltips.forEach((tooltip) => {
        tooltip.remove();
    })
}


const findItemTitle = (item, array) => {
    const id = item?.id ? item.id : item;
    return array.find((item) => item.id == id).title;
}

const findCurrencySymbol = (item, array) => {
    const id = item?.id ? item.id : item;
    return array.find((item) => item.id == id)?.symbol;
}

const countriesList = computed(() => {
    return props.countries.filter(country => country.title.toLowerCase().includes(searchCountryString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

const currencyList = computed(() => {
    return props.currencies.filter(currency => currency.title.toLowerCase().includes(searchCurrencyString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => {
            data.pcc.forEach((item, index) => {
                item.gds_id = item.gds_id && item.gds_id.id ? item.gds_id.id : item.gds_id;
                item.country_id = item.country_id && item.country_id.id ? item.country_id.id : item.country_id;
                item.type_id = item.type_id && item.type_id.id ? item.type_id.id : item.type_id;
                item.currency_id = item.currency_id && item.currency_id.id ? item.currency_id.id : item.currency_id;
            });
            return data;
        })
        .post('/admin/consolidators/store', {
            preserveScroll: true,
            onSuccess: (data) => {
                form.reset()
            }
        })
}

const submitFormPCC = () => {
    formPCC
        .transform((data) => ({
            ...data,
            gds_id: data.gds_id && data.gds_id.id ? data.gds_id.id : data.gds_id,
            country_id: data.country_id && data.country_id.id ? data.country_id.id : data.country_id,
            type_id: data.type_id && data.type_id.id ? data.type_id.id : data.type_id,
            currency_id: data.currency_id && data.currency_id.id ? data.currency_id.id : data.currency_id,
        }))
        .post('/admin/consolidator-pcc/validate', {
            preserveScroll: true,
            onSuccess: (data) => {
                form.pcc.push(formPCC.data());
                showModalChange();
                formPCC.reset();
            },
        })
}

const deleteItem = (id) => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/consolidator-pcc/destroy/'+id, {
            preserveScroll: true,
        })
    }
}


</script>

<template>

    <Head title="Add Consolidator" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <Link class="link-fx"
                          href="/admin/consolidators">
                        Consolidators
                    </Link>
                </li>
                <li class="breadcrumb-item">
                    Add consolidator
                </li>
            </ol>
        </template>

        <template v-slot:title>Consolidators</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Add consolidator</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12">
                                        <input-simple
                                            label="Consolidator name"
                                            name="title"
                                            required
                                            placeholder="Enter consolidator name"
                                            v-model="form.title"
                                            :hint="form.errors.title"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 text-end" v-if="form.pcc.length > 0">
                                        <button type="button" class="btn btn-sm btn-outline-primary" @click="showModalChange">
                                            <i class="fa fa-fw fa-plus"></i>
                                            Add another PCC</button>
                                    </div>
                                    <div class="col-12 text-center" v-else>
                                        <button type="button" class="btn btn-outline-primary" @click="showModalChange">
                                            <i class="fa fa-fw fa-plus"></i>
                                            Add PCC</button>
                                    </div>

                                    <div class="col-12 pt-3" v-if="form.pcc.length > 0">
                                        <div class="table-responsive">
                                            <table class="table table-striped table-vcenter acms-table">
                                                <thead>
                                                <tr>
                                                    <!--                                <th class="text-center" style="width: 50px;">Id</th>-->
                                                    <th>PCC</th>
                                                    <th>Bridge</th>
                                                    <th>GDS</th>
                                                    <th>Location</th>
                                                    <th>Type</th>
                                                    <th>Currency</th>
                                                    <th class="text-end" style="width: 120px;">Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in form.pcc ">
                                                        <td>{{ item.pcc }}</td>
                                                        <td>{{ item.bridge }}</td>
                                                        <td>{{ findItemTitle(item.gds_id, props.gdsList) }}</td>
                                                        <td>{{ findItemTitle(item.country_id, props.countries) }}</td>
                                                        <td>{{ findItemTitle(item.type_id, props.pccTypes) }}</td>
                                                        <td>{{ findItemTitle(item.currency_id, props.currencies) }}</td>
                                                        <td class="text-end">
                                                            <div class="btn-group">
                                                                <button type="button"
                                                                    class="btn btn-sm btn-alt-secondary"
                                                                    @click="showModalChange"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-original-title="Edit airline">
                                                                    <i class="fa fa-fw fa-pen"></i>
                                                                </button>
                                                                <Link as="button"
                                                                      class="btn btn-sm btn-alt-secondary d-flex align-items-center"
                                                                      @click="deleteItem(item.id)"
                                                                      data-bs-toggle="tooltip"
                                                                      data-bs-original-title="Remove PCC"
                                                                >
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M13.07 11.97c.3.38.3.75 0 1.13-.38.31-.76.31-1.14 0L8 9.15 4.03 13.1c-.37.31-.75.31-1.13 0-.31-.38-.31-.75 0-1.13l3.97-3.95-3.97-4c-.31-.37-.31-.74 0-1.12.38-.31.76-.31 1.13 0L8 6.89l3.97-3.96c.37-.3.75-.3 1.13 0 .31.38.31.76 0 1.13L9.13 8.02l3.94 3.95Z" fill="CurrentColor"/>
                                                                    </svg>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->

                        <modal :visibleModal="showModal" @hide-modal="showModalChange">
                            <template v-slot:modal-body>
                                <!-- Form Start -->
                                <form>
                                    <div class="row g-12">
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <input-simple
                                                label="PCC"
                                                name="pcc"
                                                required
                                                placeholder="Enter PCC"
                                                v-model="formPCC.pcc"
                                                :hint="formPCC.errors.pcc"
                                                uppercase
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <input-simple
                                                label="Bridge"
                                                name="bridge"
                                                required
                                                placeholder="Enter bridge"
                                                v-model="formPCC.bridge"
                                                :hint="formPCC.errors.bridge"
                                                uppercase
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <select-custom label="GDS"
                                                           required
                                                           @select-option="(e)=>handleSelect(e,'gds_id')"
                                                           :value="formPCC.gds_id"
                                                           :options="gdsList"
                                                           :hint="formPCC.errors.gds_id"
                                                           name="gds_id"
                                                           placeholder="Select GDS"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <select-custom label="Location"
                                                           required
                                                           enableSearch
                                                           largeWidth
                                                           @select-option="(e)=>handleSelect(e,'country_id')"
                                                           :value="formPCC.country_id"
                                                           :options="countriesList"
                                                           :hint="formPCC.errors.country_id"
                                                           @search="searchCountry"
                                                           name="country_id"
                                                           placeholder="Select Location"
                                            ></select-custom>

                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <select-custom label="Type"
                                                           required
                                                           @select-option="(e)=>handleSelect(e,'type_id')"
                                                           :value="formPCC.type_id"
                                                           :options="pccList"
                                                           :hint="formPCC.errors.type_id"
                                                           name="type_id"
                                                           placeholder="Select Type"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <select-custom label="Currency"
                                                           required
                                                           enableSearch
                                                           @select-option="(e)=>handleSelect(e,'currency_id')"
                                                           :value="formPCC.currency_id"
                                                           :options="currencyList"
                                                           :hint="formPCC.errors.currency_id"
                                                           @search="searchCurrency"
                                                           name="currency_id"
                                                           placeholder="Select Currency"
                                            ></select-custom>
                                        </div>

                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <select-custom label="Default Fare Type"
                                                           required
                                                           enableSearch
                                                           @select-option="(e)=>handleSelect(e,'default_fare_type')"
                                                           :value="formPCC.default_fare_type"
                                                           :options="['PVT', 'PUB', 'TUR', 'CRP']"
                                                           :hint="formPCC.errors.default_fare_type"
                                                           name="default_fare_type"
                                                           placeholder="Select Fare Type"
                                            ></select-custom>
                                        </div>
<!--                                        <div class="col-12">-->
<!--    -->
<!--&lt;!&ndash;                                            <div class="table-responsive">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <table class="table table-bordered acms-table table-consolidator">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <thead>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th rowspan="2"></th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th colspan="2">Net & PUB</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th colspan="2">Tours</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th>Void, {{ findCurrencySymbol(formPCC.currency_id, props.currencies) }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th>Issuing, {{ findCurrencySymbol(formPCC.currency_id, props.currencies) }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th>Ref./Exch, {{ findCurrencySymbol(formPCC.currency_id, props.currencies) }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th>Issuing, {{ findCurrencySymbol(formPCC.currency_id, props.currencies) }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th>Ref./Exch, {{ findCurrencySymbol(formPCC.currency_id, props.currencies) }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th></th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </thead>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tbody>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>Business</td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="business_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.business_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.business_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="business_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.business_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.business_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="business_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.business_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.business_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="business_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.business_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.business_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="business_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.business_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.business_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>Economy</td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="economy_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.economy_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.economy_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="economy_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.economy_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.economy_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="economy_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.economy_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.economy_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="economy_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.economy_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.economy_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="economy_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.economy_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.economy_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>Premium</td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="premium_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.premium_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.premium_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="premium_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.premium_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.premium_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="premium_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.premium_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.premium_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="premium_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.premium_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.premium_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="premium_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.premium_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.premium_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tbody>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </table>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->

<!--                                        </div>-->
                                        <div class="col-12">
                                            <textarea-simple
                                                label="Special notes"
                                                name="notes"
                                                placeholder="Enter special notes"
                                                v-model="formPCC.notes"
                                                :hint="formPCC.errors.notes"
                                            ></textarea-simple>
                                        </div>
                                    </div>
                                </form>
                                <!-- Form End -->
                            </template>

                            <template v-slot:modal-footer>
                                <button type="button" class="btn btn-primary" @click="submitFormPCC">Save</button>
                            </template>
                        </modal>

                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <button type="button" class="btn btn-alt-secondary mb-3 mb-sm-0 me-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button" class="btn btn-primary" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
