<script setup>

import { Head, useForm, Link, router } from '@inertiajs/vue3'
import { ref } from 'vue';

import Layout from '../Layout.vue'
import SelectCustom from "../../components/UI/SelectCustom.vue";
import TableColumnSort from "../../components/UI/TableColumnSort.vue";

import Table from "../../services/serviceTable";
const TableService = new Table();

const props = defineProps({
    collection: Object,
    search: String,
    timezones: Array,
    country: String,
    column: String,
    direction: String,
    actions: {
        type: Array,
        default: () => []
    },
})

const searchTimeout = ref(null)

const form = useForm({
    search: props.search,
    limit: props.collection.per_page,
    page: props.collection.current_page,
    country: props.country,
    column: '',
    direction: '',
})

const handleLimitSelect = (value) => {
    form.limit = value
    formRequest();
}

const formRequestInput = () => {
    formRequest();
}

const formRequest = () => {
    clearTimeout(searchTimeout.value)
    searchTimeout.value = setTimeout(() => {
        form
            .transform((data) => {
                const {
                    search, limit, page, country, column, direction
                } = data

                let object = {}

                if (search?.length > 0) object.search = search
                if (limit) object.limit = limit
                if (page != 1) object.page = 1
                if (country?.length > 0) object.country = country
                if (column?.length > 0) object.column = column
                if (direction?.length > 0) object.direction = direction

                return object
            })
            .get('/admin/airlines', {
                preserveScroll: true,
                preserveState: true,
            })
    }, 300)
}

const deleteItem = (id) => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/airlines/destroy/'+id, {
            preserveScroll: true,
        })
    }
}

const handleSortColumn = (key) => {
    TableService.serviceSortableColumn(key, form);
    formRequest();
}

const columns = [
    {'slug': 'id', width: '50px', title: 'Id'},
    {'slug': 'title', width: '320px', title: 'Airline name'},
    {'slug': 'iata', width: '60px', title: 'IATA'},
    {'slug': 'alliance', width: '160px', title: 'Alliance'},
    {'slug': 'hub', width: '160px', title: 'HUB'},
    {'slug': 'country_title', width: '160px', title: 'Country'},
    {'slug': 'skytrax', width: '100px', title: 'SkyTrax', align: 'center'},
    {'slug': 'fleet_age', width: '100px', title: 'Fleet age', align: 'center'},
    {'slug': 'fleet_size', width: '100px', title: 'Fleet size', align: 'center'},
    {'slug': 'website', width: '60px', title: 'Site', align: 'center'},
    {'slug': 'check_in', width: '100px', title: 'Check-in', align: 'center'},
    {'slug': 'baggage', width: '100px', title: 'Baggage', align: 'center'},



]

</script>

<template>

    <Head title="Airlines" />

    <Layout>

        <template v-slot:buttons>
            <Link class="btn btn-primary btn-sm ms-2"
                  v-if="actions.includes('Create')"
                  href="/admin/airlines/create">
                <i class="fa fa-fw fa-plus"></i>
                Add airline
            </Link>
        </template>

        <template v-slot:title>Airlines</template>

        <template v-slot:content>
            <div class="block block-rounded">
                <div class="block-content block-content-full">
                    <div class="table__header">
                        <div class="table__search">
                            <input class="form-control" type="text" name="search" @input="formRequestInput()" v-model="form.search" placeholder="Search">
                        </div>
                        <div class="table__per_page">
                            <select-custom
                                @select-option="handleLimitSelect"
                                :value="form.limit"
                                :options="[10, 20, 30, 50, 100]"
                                name="per_page"
                            ></select-custom>
                        </div>
                    </div>
                    <div class="table-responsive" v-if="collection.data.length > 0 && search?.length >= 0">
                        <table class="table table-striped table-vcenter acms-table">
                            <thead>
                            <tr>
                                <th v-for="(column, index) in columns"
                                    :style="{width: column.width, textAlign: column.align}"
                                    :key="index"
                                >
                                    <table-column-sort
                                        :title="column.title"
                                        :direction="form.column === column.slug ? form.direction : ''"
                                        @sortColumn="handleSortColumn(column.slug)"
                                    ></table-column-sort>
                                </th>
                                <th class="text-end" style="width: 120px;">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in collection.data ">
                                <th scope="row">{{ item.id }}</th>
                                <td>
                                    <template v-if="actions.includes('Read')">
                                        <Link class="table-link"
                                              :href="'/admin/airlines/edit/'+ item.id">
                                            {{ item.title }}
                                        </Link>
                                    </template>
                                    <template v-else>
                                        {{ item.title }}
                                    </template>
                                </td>
                                <td>{{ item?.iata ?? '–'  }}</td>
                                <td>{{ item?.alliance ?? '–' }}</td>
                                <td>{{ item.airports ? item?.airports : '–' }}</td>
                                <td>{{ item?.country_title ?? '–' }}</td>
                                <td style="text-align: center">{{ item?.skytrax ?? '–' }}</td>
                                <td style="text-align: center">{{ item?.fleet_age ?? '–' }}</td>
                                <td style="text-align: center">{{ item?.fleet_size ?? '–' }}</td>
                                <td style="text-align: center">
                                    <a v-if="item?.website" :href="item?.website" target="_blank">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.64 8.28a3.47 3.47 0 0 0 0-4.93 3.53 3.53 0 0 0-4.52-.37l-.04.03a.77.77 0 0 0-.18 1.07c.25.35.73.43 1.08.18l.04-.02a1.96 1.96 0 0 1 2.52.2c.76.76.76 2 0 2.75l-2.72 2.7c-.77.77-2 .77-2.77 0a1.93 1.93 0 0 1-.2-2.5l.02-.03a.77.77 0 0 0-.18-1.08.78.78 0 0 0-1.08.18l-.03.04c-1 1.38-.84 3.28.37 4.49a3.53 3.53 0 0 0 4.96 0l2.73-2.7ZM1.03 7.72a3.47 3.47 0 0 0 0 4.93 3.53 3.53 0 0 0 4.52.37l.04-.03a.77.77 0 0 0 .18-1.07.78.78 0 0 0-1.08-.18l-.04.02c-.78.56-1.85.47-2.52-.2a1.93 1.93 0 0 1 0-2.75l2.72-2.7a1.95 1.95 0 0 1 2.97 2.5l-.02.03a.77.77 0 0 0 .18 1.08c.35.24.83.16 1.08-.18l.03-.04c1-1.38.84-3.28-.37-4.49a3.53 3.53 0 0 0-4.97 0l-2.72 2.7Z" fill="#4C78DD"/>
                                        </svg>
                                    </a>
                                    <template v-else>–</template>
                                </td>
                                <td style="text-align: center">
                                    <a v-if="item?.check_in" :href="item?.check_in" target="_blank">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.64 8.28a3.47 3.47 0 0 0 0-4.93 3.53 3.53 0 0 0-4.52-.37l-.04.03a.77.77 0 0 0-.18 1.07c.25.35.73.43 1.08.18l.04-.02a1.96 1.96 0 0 1 2.52.2c.76.76.76 2 0 2.75l-2.72 2.7c-.77.77-2 .77-2.77 0a1.93 1.93 0 0 1-.2-2.5l.02-.03a.77.77 0 0 0-.18-1.08.78.78 0 0 0-1.08.18l-.03.04c-1 1.38-.84 3.28.37 4.49a3.53 3.53 0 0 0 4.96 0l2.73-2.7ZM1.03 7.72a3.47 3.47 0 0 0 0 4.93 3.53 3.53 0 0 0 4.52.37l.04-.03a.77.77 0 0 0 .18-1.07.78.78 0 0 0-1.08-.18l-.04.02c-.78.56-1.85.47-2.52-.2a1.93 1.93 0 0 1 0-2.75l2.72-2.7a1.95 1.95 0 0 1 2.97 2.5l-.02.03a.77.77 0 0 0 .18 1.08c.35.24.83.16 1.08-.18l.03-.04c1-1.38.84-3.28-.37-4.49a3.53 3.53 0 0 0-4.97 0l-2.72 2.7Z" fill="#4C78DD"/>
                                        </svg>
                                    </a>
                                    <template v-else>–</template>
                                </td>
                                <td style="text-align: center">
                                    <a v-if="item?.baggage" :href="item?.baggage" target="_blank">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.64 8.28a3.47 3.47 0 0 0 0-4.93 3.53 3.53 0 0 0-4.52-.37l-.04.03a.77.77 0 0 0-.18 1.07c.25.35.73.43 1.08.18l.04-.02a1.96 1.96 0 0 1 2.52.2c.76.76.76 2 0 2.75l-2.72 2.7c-.77.77-2 .77-2.77 0a1.93 1.93 0 0 1-.2-2.5l.02-.03a.77.77 0 0 0-.18-1.08.78.78 0 0 0-1.08.18l-.03.04c-1 1.38-.84 3.28.37 4.49a3.53 3.53 0 0 0 4.96 0l2.73-2.7ZM1.03 7.72a3.47 3.47 0 0 0 0 4.93 3.53 3.53 0 0 0 4.52.37l.04-.03a.77.77 0 0 0 .18-1.07.78.78 0 0 0-1.08-.18l-.04.02c-.78.56-1.85.47-2.52-.2a1.93 1.93 0 0 1 0-2.75l2.72-2.7a1.95 1.95 0 0 1 2.97 2.5l-.02.03a.77.77 0 0 0 .18 1.08c.35.24.83.16 1.08-.18l.03-.04c1-1.38.84-3.28-.37-4.49a3.53 3.53 0 0 0-4.97 0l-2.72 2.7Z" fill="#4C78DD"/>
                                        </svg>
                                    </a>
                                    <template v-else>–</template>
                                </td>
                                <td class="text-end">
                                    <div class="btn-group">
                                        <Link
                                            v-if="actions.includes('Read')"
                                              class="btn btn-sm btn-alt-secondary"
                                              :href="'/admin/airlines/edit/'+ item.id"
                                              data-bs-toggle="tooltip"
                                              data-bs-original-title="Edit airline">
                                            <i class="fa fa-fw fa-pen"></i>
                                        </Link>
                                        <Link as="button"
                                              v-if="actions.includes('Delete')"
                                              class="btn btn-sm btn-alt-secondary d-flex align-items-center"
                                              @click="deleteItem(item.id)"
                                              data-bs-toggle="tooltip"
                                              data-bs-original-title="Remove airline"
                                        >
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.07 11.97c.3.38.3.75 0 1.13-.38.31-.76.31-1.14 0L8 9.15 4.03 13.1c-.37.31-.75.31-1.13 0-.31-.38-.31-.75 0-1.13l3.97-3.95-3.97-4c-.31-.37-.31-.74 0-1.12.38-.31.76-.31 1.13 0L8 6.89l3.97-3.96c.37-.3.75-.3 1.13 0 .31.38.31.76 0 1.13L9.13 8.02l3.94 3.95Z" fill="CurrentColor"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-else-if="collection.data.length == 0 && search?.length == 0">
                        <div class="d-flex flex-column align-items-center">
                            <div class="alert alert-info alert-dismissible text-center w-100" role="alert">
                                <p class="mb-0">
                                    No airlines here! Start with creating a new airline.
                                </p>
                            </div>
                            <Link class="btn btn-primary"
                                  href="/admin/airlines/create">
                                <i class="fa fa-fw fa-plus"></i>
                                Add airline
                            </Link>
                        </div>
                    </div>

                    <div v-else-if="collection.data.length == 0 && search?.length > 0">
                        <div class="alert alert-info alert-dismissible text-center" role="alert">
                            <p class="mb-0">
                                There are no airlines with such name. Try to refine <strong>your search</strong>
                            </p>
                        </div>
                    </div>

                    <div class="pagination__block" v-if="collection.last_page > 1">
                        <ul class="pagination justify-content-center">
                            <li class="paginate_button page-item"
                                v-for="link in collection.links"
                                :class="{'active': link.active}"
                            >
                                <Link class="page-link" as="button"
                                      :href="link?.url"
                                      v-html="link.label"
                                ></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>

    </Layout>

</template>
