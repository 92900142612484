<script setup>

import { Head, useForm } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";

const form = useForm({
    title: '',
    gds_code: '',
    exit: false,
})

const handleInput = (id, key) => {
    form[key] = id;
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .post('/admin/meals/store' , {
            preserveScroll: true,
            onSuccess: () => form.reset(),
        })
}

</script>

<template>

    <Head title="Add meal" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/meals">Meals</a>
                </li>
                <li class="breadcrumb-item">
                    Add meal
                </li>
            </ol>
        </template>

        <template v-slot:title>Meals</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Add meal</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6">
                                        <input-simple
                                            label="Meal name"
                                            name="title"
                                            required
                                            placeholder="Enter meal name"
                                            v-model="form.title"
                                            :hint="form.errors.title"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <input-simple
                                            label="GDS"
                                            name="gds_code"
                                            required
                                            placeholder="Enter GDS code"
                                            v-model="form.gds_code"
                                            :hint="form.errors.gds_code"
                                            uppercase
                                        ></input-simple>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <button type="button" class="btn btn-alt-secondary mb-3 mb-sm-0 me-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button" class="btn btn-primary" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
