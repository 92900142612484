<script setup>

import { Head, useForm, Link, router } from '@inertiajs/vue3'
import { ref } from 'vue';

import Layout from '../Layout.vue'
import SelectCustom from "../../components/UI/SelectCustom.vue";
import TableColumnSort from "../../components/UI/TableColumnSort.vue";

import Table from "../../services/serviceTable";
const TableService = new Table();

const props = defineProps({
    collection: Object,
    search: String,
    column: String,
    direction: String,
    actions: {
        type: Array,
        default: () => []
    },
})

const searchTimeout = ref(null)

const form = useForm({
    search: props.search,
    limit: props.collection.per_page,
    page: props.collection.current_page,
    column: '',
    direction: '',
})

const handleLimitSelect = (value) => {
    form.limit = value
    formRequest();
}

const formRequestInput = () => {
    // if (form.search.length < 3 && form.search.length !== 0) return false
    formRequest();
}

const formRequest = () => {
    clearTimeout(searchTimeout.value)
    searchTimeout.value = setTimeout(() => {
        form
            .transform((data) => {
                const {
                    search, limit, page, column, direction
                } = data

                let object = {}

                if (search?.length > 0) object.search = search
                if (limit) object.limit = limit
                if (page != 1) object.page = 1
                if (column?.length > 0) object.column = column
                if (direction?.length > 0) object.direction = direction

                return object
            })
            .get('/admin/countries', {
                preserveScroll: true,
                preserveState: true,
            })
    }, 300)
}

const deleteItem = (id) => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/countries/destroy/'+id, {
            preserveScroll: true,
        })
    }
}


const handleSortColumn = (key) => {
    TableService.serviceSortableColumn(key, form);
    formRequest();
}

const columns = [
    {'slug': 'id', width: '50px', title: 'Id'},
    {'slug': 'image', width: '50px', title: ''},
    {'slug': 'title', width: '320px', title: 'Country name'},
    {'slug': 'iata', width: '180px', title: 'ISO2'},
    {'slug': 'capital', width: '220px', title: 'Capital'},
    {'slug': 'capital_iata', width: '220px', title: 'Capital IATA'},
    {'slug': 'currency', width: '220px', title: 'Currency'},
    {'slug': 'dial_codes', width: '220px', title: 'Dial'},
    {'slug': 'population', width: '220px', title: 'Population', align: 'right'},
    {'slug': 'cities_count', width: '220px', title: 'Cities', align: 'right'},
]

</script>

<template>

    <Head title="Countries" />

    <Layout>

        <template v-slot:buttons>
            <Link class="btn btn-primary btn-sm ms-2"
                  v-if="actions.includes('Create')"
                  href="/admin/countries/create">
                <i class="fa fa-fw fa-plus"></i>
                Add country
            </Link>
        </template>

        <template v-slot:title>Countries</template>

        <template v-slot:content>
            <div class="block block-rounded">
                <div class="block-content block-content-full">
                    <div class="table__header">
                        <div class="table__search">
                            <input class="form-control" type="text" name="search" @input="formRequestInput()" v-model="form.search" placeholder="Search">
                        </div>
                        <div class="table__per_page">
                            <select-custom
                                   @select-option="handleLimitSelect"
                                   :value="form.limit"
                                   :options="[10, 20, 30, 50, 100]"
                                   name="per_page"
                            ></select-custom>
                        </div>
                    </div>

                    <div class="table-responsive" v-if="collection.data.length > 0 && search?.length >= 0">
                        <table class="table table-striped table-vcenter acms-table">
                            <thead>
                            <tr>
                                <th v-for="(column, index) in columns"
                                    :style="{width: column.width, textAlign: column.align}"
                                    :key="index"
                                >
                                    <template v-if="column.slug == 'image'">
                                        {{ column.title }}
                                    </template>
                                    <table-column-sort v-else
                                        :title="column.title"
                                        :direction="form.column === column.slug ? form.direction : ''"
                                        @sortColumn="handleSortColumn(column.slug)"
                                    ></table-column-sort>
                                </th>
                                <th class="text-end" style="width: 120px;">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in collection.data ">
                                <th scope="row">{{ item.id }}</th>
                                <td><img class="flag__image" v-if="item?.image" :src="item.image" :alt="item?.title"></td>
                                <td>
                                    <template v-if="actions.includes('Read')">
                                        <Link class="table-link"
                                              :href="'/admin/countries/edit/'+ item.id">
                                            {{ item.title }}
                                        </Link>
                                    </template>
                                    <template v-else>
                                        {{ item.title }}
                                    </template>
                                </td>
                                <td>{{ item?.iata ?? '–' }}</td>
                                <td>{{ item?.capital ?? '–' }}</td>
                                <td>{{ item?.capital_iata ?? '–' }}</td>
                                <td>{{ item?.currency ?? '–' }}</td>
                                <td>{{ item.dial_codes ? item?.dial_codes : '–' }}</td>
                                <td style="text-align: right;">
                                    {{ item?.population ?? '–' }}</td>
                                <td style="text-align: right;">
                                    <Link class="table-link"
                                          :href="'/admin/cities?country='+ item.title">
                                        {{ item.cities_count }}
                                    </Link>
                                </td>
                                <td class="text-end">
                                    <div class="btn-group">
                                        <Link
                                            v-if="actions.includes('Read')"
                                              class="btn btn-sm btn-alt-secondary"
                                              :href="'/admin/countries/edit/'+ item.id"
                                              title="Edit country"
                                              data-bs-toggle="tooltip"
                                              data-bs-original-title="Edit country">
                                            <i class="fa fa-fw fa-pen"></i>
                                        </Link>
                                        <Link as="button"
                                              v-if="actions.includes('Delete')"
                                              class="btn btn-sm btn-alt-secondary d-flex align-items-center"
                                              @click="deleteItem(item.id)"
                                              data-bs-toggle="tooltip"
                                              data-bs-original-title="Remove country"
                                        >
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.07 11.97c.3.38.3.75 0 1.13-.38.31-.76.31-1.14 0L8 9.15 4.03 13.1c-.37.31-.75.31-1.13 0-.31-.38-.31-.75 0-1.13l3.97-3.95-3.97-4c-.31-.37-.31-.74 0-1.12.38-.31.76-.31 1.13 0L8 6.89l3.97-3.96c.37-.3.75-.3 1.13 0 .31.38.31.76 0 1.13L9.13 8.02l3.94 3.95Z" fill="CurrentColor"/>
                                            </svg>
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-else-if="collection.data.length == 0 && search?.length == 0">
                        <div class="d-flex flex-column align-items-center">
                            <div class="alert alert-info alert-dismissible text-center w-100" role="alert">
                                <p class="mb-0">
                                    No countries here! Start with creating a new country.
                                </p>
                            </div>
                            <Link class="btn btn-primary"
                                  href="/admin/countries/create">
                                <i class="fa fa-fw fa-plus"></i>
                                Add country
                            </Link>
                        </div>
                    </div>

                    <div v-else-if="collection.data.length == 0 && search?.length > 0">
                        <div class="alert alert-info alert-dismissible text-center" role="alert">
                            <p class="mb-0">
                                There are no countries with such name. Try to refine <strong>your search</strong>
                            </p>
                        </div>
                    </div>

                    <div class="pagination__block" v-if="collection.last_page > 1">
                        <ul class="pagination justify-content-center">
                            <li class="paginate_button page-item"
                                v-for="link in collection.links"
                                :class="{'active': link.active}"
                            >
                                <Link class="page-link" as="button"
                                      :href="link?.url"
                                      v-html="link.label"
                                ></Link>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </template>

    </Layout>

</template>
