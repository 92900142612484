<template>
    <div class="form-group"
        :class="hint ? 'has-error' : '' "
    >
        <input class="form-control form-control-lg form-control-alt py-3"
               required="required"
               :maxlength="maxlength"
               :name="name"
               :type="type"
               :value="modelValue"
               :id="name"
               :placeholder="placeholder"
               @input="updateInput"
               :required="required"
               :disabled="disabled"
        >
        <div class="form-hint" v-if="hint">
            {{ hint }}
        </div>
    </div>
</template>


<script>
export default {
    name: "InputLoginSimple",
    props: {
        type: {
            type: String,
            default: "text",
        },
        name: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        hint: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        maxlength: {
            type: Number,
            default: 255,
        },
        modelValue: {
            type: [String, Number],
            default: "",
        },
    },
    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    },
    // mounted() {
    //     // this.isDisabled = this.disable;
    //     // console.log('isDisabled: ', this.isDisabled)
    // }
}
</script>

<style>

</style>
