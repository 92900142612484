<script setup>

import { useForm } from '@inertiajs/vue3'
import InputLoginSimple from "../../components/UI/InputLoginSimple.vue";
import {reactive, watch} from "vue";

const form = useForm({
    email: '',
    password: '',
})

const locState = reactive({
    showPassword: false,  // new state property
});
function toggleShowPassword() {
    locState.showPassword = !locState.showPassword;
}

const submitForm = () => {
    form
        .post('/admin/login', {
            preserveScroll: true,
            onSuccess: () => form.reset(),
        })
}

</script>

<!--<template>-->

<!--    <Head title="Login" />-->

<!--    <Layout>-->

<!--        <template v-slot:content>-->

<!--            <div class="text-center mb-5">-->
<!--                <h1 class="fw-bold mb-2">-->
<!--                    Sign In-->
<!--                </h1>-->
<!--                <p class="fw-medium text-muted">-->
<!--                    Welcome-->
<!--                </p>-->
<!--            </div>&lt;!&ndash; END Header &ndash;&gt;-->
<!--            &lt;!&ndash; Sign In Form &ndash;&gt;-->
<!--            <div class="row g-0 justify-content-center">-->
<!--                <div class="col-sm-8 col-xl-4">-->
<!--                    <form>-->
<!--&lt;!&ndash;                        @csrf&ndash;&gt;-->
<!--                        <div class="mb-4">-->
<!--                            <input-login-simple-->
<!--                                name="email"-->
<!--                                required-->
<!--                                placeholder="Email"-->
<!--                                v-model="form.email"-->
<!--                                :hint="form.errors['email']"-->
<!--                            ></input-login-simple>-->
<!--                        </div>-->
<!--                        <div class="mb-4">-->
<!--                            <input-login-simple-->
<!--                                name="password"-->
<!--                                required-->
<!--                                type="password"-->
<!--                                placeholder="Password"-->
<!--                                v-model="form.password"-->
<!--                                :hint="form.errors['password']"-->
<!--                            ></input-login-simple>-->
<!--                        </div>-->
<!--                        <div class="d-flex justify-content-between align-items-center mb-4">-->
<!--                            <div>-->
<!--                            </div>-->
<!--                            <div>-->
<!--                                <button type="button" class="btn btn-lg btn-primary" @click="submitForm()"><i class="fa fa-fw fa-sign-in-alt me-1 opacity-50"></i> Sign In</button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </form>-->
<!--                </div>-->
<!--            </div>&lt;!&ndash; END Sign In Form &ndash;&gt;-->

<!--        </template>-->

<!--    </Layout>-->

<!--</template>-->

<template>

    <div>
        <div class="row g-0 bg-primary-dark-op">
            <div style="background-image: url('/media/admin/main-bg.svg'); background-color: #ffffff; background-repeat: no-repeat; background-position: center center; width: 38.2%;" class="hero-static d-none d-lg-flex flex-column justify-content-center">
                <div class="p-4 p-xl-5 flex-grow-1 d-flex align-items-center">
                </div>
            </div>
            <div class="hero-static d-flex flex-column align-items-center" style="background-color: #4f5c68; width: 61.8%">
                <div class="p-4 w-100 flex-grow-1 d-flex align-items-center">
                    <div class="w-100">
                        <div class="text-center mb-5">
                            <h1 class="fw-bold mb-2" style="color: white;">
                                Where Every Journey is Exclusive
                            </h1>
                        </div>
                        <div class="row g-0 justify-content-center">
                            <div class="col-xl-6" style="display: flex; flex-direction: column;">
                                <div class="mb-4 col-sm-12">
                                    <input-login-simple
                                        name="email"
                                        required
                                        placeholder="Email"
                                        v-model="form.email"
                                        :hint="form.errors['email']"
                                    ></input-login-simple>
                                </div>
                                <div class="col-sm-12" style="display: flex">
                                    <div class="col-sm-12">
                                        <input-login-simple
                                            name="password"
                                            required
                                            type="password"
                                            placeholder="Password"
                                            v-model="form.password"
                                            :hint="form.errors['password']"
                                        ></input-login-simple>
                                    </div>
                                    <acms-v-icon class="ms-2 col-sm-2" style="align-self: center; padding-bottom: 15px" size="30" name="eye" :color="locState.showPassword ? '#000' : '#FFF'" @click="toggleShowPassword"></acms-v-icon>
                                </div>
                                <div class="d-flex align-items-center mb-4 mt-2" style="justify-content: center; padding-right: 55px">
                                    <button type="button" class="btn btn-lg" style="background-color: white;" @click="submitForm()">
                                        <i class="fa fa-fw fa-sign-in-alt me-1 opacity-50"></i> Sign In
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
