<script setup>

import { Head, useForm, Link, router } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";
import RadioButtonsYesNo from "../../components/UI/RadioButtonsYesNo.vue";

const props = defineProps({
    engineCategories: Object,
    resource: Object,
    actions: {
        type: Array,
        default: () => []
    },
});

const resource = props.resource;
const engineCategories = props.engineCategories;

const form = useForm({
    title: resource.title,
    iata: resource.iata,
    icao: resource.icao,
    capacity: resource.capacity,
    engine_category_id: resource.engine_category,
    engine_quantity: resource.engine_quantity,
    wide_body: resource.wide_body,
    pressurized: resource.pressurized,
    resource_id: resource.id,
    exit: false,
})

const handleRadioButtonInput = (id, key) => {
    form[key] = id == 'true' ? true : false
}

const handleSelect = (option, key) => {
    form[key] = option;
}

const handleChangeTag = (tags) => {
    form.error_title = tags;
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            engine_category_id: data.engine_category_id && data.engine_category_id.id ? data.engine_category_id.id : data.engine_category_id,
        }))
        .put('/admin/aircraft/update/' + resource.id, {
        preserveScroll: true,
        // preserveState: false,
        onSuccess: () => {
            router.reload({ only: ['form'] }) // при сохранении без выходя обновляем данные в форме
        },
    })
}

const deleteItem = () => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/aircraft/destroy/' + resource.id, {
            preserveScroll: true,
        })
    }
}

</script>

<template>

    <Head title="Edit aircraft" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <Link class="link-fx"
                          href="/admin/aircraft">
                        Aircrafts
                    </Link>
                </li>
                <li class="breadcrumb-item">
                    Edit aircraft
                </li>
            </ol>
        </template>

        <template v-slot:title>Aircrafts</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Edit aircraft</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Aircraft name"
                                            name="title"
                                            required
                                            placeholder="Enter aircraft name"
                                            v-model="form.title"
                                            :hint="form.errors.title"
                                        ></input-simple>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="IATA"
                                            name="iata"
                                            required
                                            placeholder="Enter IATA"
                                            v-model="form.iata"
                                            :hint="form.errors.iata"
                                            uppercase
                                        ></input-simple>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="ICAO"
                                            name="icao"
                                            placeholder="Enter ICAO"
                                            v-model="form.icao"
                                            :hint="form.errors.icao"
                                            uppercase
                                        ></input-simple>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Capacity"
                                            name="capacity"
                                            placeholder="Passengers capacity"
                                            v-model="form.capacity"
                                            :hint="form.errors.capacity"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <select-custom label="Category"
                                                       @select-option="(e)=>handleSelect(e,'engine_category_id')"
                                                       :value="form.engine_category_id"
                                                       :options="engineCategories"
                                                       :hint="form.errors.engine_category_id"
                                                       name="engine_category_id"
                                                       placeholder="Select category"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Engines"
                                            name="engine_quantity"
                                            placeholder="Number of engines"
                                            v-model="form.engine_quantity"
                                            :hint="form.errors.engine_quantity"
                                        ></input-simple>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <radio-buttons-yes-no
                                            label="Wide body"
                                            name="wide_body"
                                            :option="resource.wide_body"
                                            @changeRadio="(e)=>handleRadioButtonInput(e,'wide_body')"
                                        />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <radio-buttons-yes-no
                                            label="Pressurized"
                                            name="pressurized"
                                            :option="resource.pressurized"
                                            @changeRadio="(e)=>handleRadioButtonInput(e,'pressurized')"
                                        />
                                    </div>

                                </div>
                            </div>

                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Delete')||actions.includes('Update')">
            <button type="button"
                    v-if="actions.includes('Delete')"
                    class="btn btn-outline-danger mb-3 mb-sm-0" @click="deleteItem">Delete aircraft</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-alt-secondary mb-3 mb-sm-0 ms-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-primary ms-sm-3" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
