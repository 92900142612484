<script setup>

import { computed, ref } from 'vue';
import { Head, useForm } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";

const props = defineProps({
    countries: Object,
});

const form = useForm({
    title: '',
    code: '',
    country_id: '',
    exit: false,
})

const searchCountryString = ref('');

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    form[key] = option;
}
const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            country_id: data.country_id && data.country_id.id ? data.country_id.id : data.country_id,
        }))
        .post('/admin/states/store' , {
            preserveScroll: true,
            onSuccess: () => form.reset(),
        })
}

const searchCountry = (value) => {
    searchCountryString.value = value;
}

const countriesList = computed(() => {
    return props.countries.filter(country => country.title.toLowerCase().includes(searchCountryString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
}) ;

</script>

<template>

    <Head title="Add state" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/states">States</a>
                </li>
                <li class="breadcrumb-item">
                    Add state
                </li>
            </ol>
        </template>

        <template v-slot:title>States</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Add state</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-4">
                                        <input-simple
                                            label="State name"
                                            name="title"
                                            required
                                            placeholder="Enter state name"
                                            v-model="form.title"
                                            :hint="form.errors.title"
                                        ></input-simple>
                                    </div>
                                  <div class="col-12 col-sm-4">
                                    <input-simple
                                        label="State code"
                                        name="code"
                                        required
                                        placeholder="Enter state code"
                                        v-model="form.code"
                                        :hint="form.errors.code"
                                    ></input-simple>
                                  </div>
                                    <div class="col-12 col-sm-4">
                                        <select-custom label="Country"
                                                       required
                                                       enableSearch
                                                       @select-option="(e)=>handleSelect(e,'country_id')"
                                                       :value="form.country_id"
                                                       :options="countriesList"
                                                       :hint="form.errors.country_id"
                                                       @search="searchCountry"
                                                       name="country_id"
                                                       placeholder="Select country"
                                        ></select-custom>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <button type="button" class="btn btn-alt-secondary mb-3 mb-sm-0 me-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button" class="btn btn-primary" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
