<script setup>

import { Head, useForm } from '@inertiajs/vue3'
import { computed, ref } from "vue";
import axios from "axios";

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";

const props = defineProps({
    countries: Object,
});

const form = useForm({
    title: '',
    country_id: '',
    city_id: '',
    gmt: '',
    dst: '',
    exit: false,
})

const searchCountryString = ref('');
const searchCityString = ref('');
const cities = ref([]);

const searchCountry = (value) => {
    searchCountryString.value = value;
}

const searchCity = (value) => {
    searchCityString.value = value;
}

const handleInput = (id, key) => {
    form[key] = id;
}

const handleCheckboxButtonInput = (value, key) => {
    form[key] = value
}

const handleSelect = (option, key) => {
    form[key] = option;
    selectRequest(key, option);
}

const isCountrySelected = computed(() => !!form.country_id && cities.value.length > 0)

const countriesList = computed(() => {
    return props.countries.filter(country => country.title.toLowerCase().includes(searchCountryString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

const citiesList = computed(() => {
    return cities.value.filter(city => city.title.toLowerCase().includes(searchCityString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});


const selectRequest = (key, option) => {
    if (key === 'country_id') {
        citiesList.value = [];
        form.city_id = '';
        axios
            .post('/admin/cities/search/list', {
                country_id: option.id,
            })
            .then((response) => {
                if (response.data.data.length > 0) cities.value = response.data.data;
            })
    }
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            country_id: data.country_id && data.country_id.id ? data.country_id.id : data.country_id,
            city_id: data.city_id && data.city_id.id ? data.city_id.id : data.city_id,
        }))
        .post('/admin/timezones/store' , {
            preserveScroll: true,
            onSuccess: () => form.reset(),
        })
}

</script>

<template>

    <Head title="Add time zones" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/timezones">Time zones</a>
                </li>
                <li class="breadcrumb-item">
                    Add time zone
                </li>
            </ol>
        </template>

        <template v-slot:title>Time zones</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Add time zone</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Time zone name"
                                            name="title"
                                            required
                                            placeholder="Enter time zone name"
                                            v-model="form.title"
                                            :hint="form.errors.title"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <select-custom label="Country"
                                                       required
                                                       enableSearch
                                                       @select-option="(e)=>handleSelect(e,'country_id')"
                                                       :value="form.country_id"
                                                       :options="countriesList"
                                                       :hint="form.errors.country_id"
                                                       @search="searchCountry"
                                                       name="country_id"
                                                       placeholder="Select country"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <select-custom label="City"
                                                       required
                                                       enableSearch
                                                       :disabled="!isCountrySelected"
                                                       @select-option="(e)=>handleSelect(e,'city_id')"
                                                       :value="form.city_id"
                                                       :options="citiesList"
                                                       :hint="form.errors.city_id"
                                                       @search="searchCity"
                                                       name="city_id"
                                                       placeholder="Select city"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="GMT"
                                            name="gmt"
                                            required
                                            placeholder="Enter GMT"
                                            v-model="form.gmt"
                                            :hint="form.errors.gmt"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="DST"
                                            name="dst"
                                            required
                                            placeholder="Enter DST"
                                            v-model="form.dst"
                                            :hint="form.errors.dst"
                                        ></input-simple>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <button type="button" class="btn btn-alt-secondary mb-3 mb-sm-0 me-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button" class="btn btn-primary" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
