<script setup>

import { computed, ref } from 'vue';
import { Head, router, useForm } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";

const props = defineProps({
    countries: Object,
    resource: Object,
    actions: {
        type: Array,
        default: () => []
    },
});

const resource = props.resource;

const form = useForm({
    title: resource.title,
    code: resource.code,
    country_id: resource.country,
    exit: false,
})

const searchCountryString = ref('');

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    form[key] = option;
}

const searchCountry = (value) => {
    searchCountryString.value = value;
}

const countriesList = computed(() => {
    return props.countries.filter(country => country.title.toLowerCase().includes(searchCountryString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
}) ;

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            country_id: data.country_id && data.country_id.id ? data.country_id.id : data.country_id,
        }))
        .put('/admin/states/update/' + resource.id , {
            preserveScroll: true,
            // preserveState: false,
            onSuccess: () => {
                router.reload({ only: ['form'] }) // при сохранении без выходя обновляем данные в форме
            },
        })
}


const deleteItem = () => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/states/destroy/' + resource.id, {
            preserveScroll: true,
        })
    }
}

</script>

<template>

    <Head title="Edit state" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/states">States</a>
                </li>
                <li class="breadcrumb-item">
                    Edit state
                </li>
            </ol>
        </template>

        <template v-slot:title>States</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Edit state</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-4">
                                        <input-simple
                                            label="State name"
                                            name="title"
                                            required
                                            placeholder="Enter state name"
                                            v-model="form.title"
                                            :hint="form.errors.title"
                                        ></input-simple>
                                    </div>
                                  <div class="col-12 col-sm-4">
                                    <input-simple
                                        label="State code"
                                        name="code"
                                        required
                                        placeholder="Enter state code"
                                        v-model="form.code"
                                        :hint="form.errors.code"
                                    ></input-simple>
                                  </div>
                                    <div class="col-12 col-sm-4">
                                        <select-custom label="Country"
                                                       required
                                                       enableSearch
                                                       @select-option="(e)=>handleSelect(e,'country_id')"
                                                       :value="form.country_id"
                                                       :options="countriesList"
                                                       :hint="form.errors.country_id"
                                                       @search="searchCountry"
                                                       name="country_id"
                                                       placeholder="Select country"
                                        ></select-custom>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Delete')||actions.includes('Update')">
            <button type="button"
                    v-if="actions.includes('Delete')"
                    class="btn btn-outline-danger mb-3 mb-sm-0" @click="deleteItem">Delete state</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-alt-secondary mb-3 mb-sm-0 ms-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-primary ms-sm-3" @click="submitForm(false)">Save</button>
        </template>
    </Layout>

</template>

<style>

</style>
