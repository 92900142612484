<script setup>

import { Head, useForm } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";

const props = defineProps({
    codeTypes: Object,
    GDSList: Object,
});

const form = useForm({
    gds_id: '',
    code_type: '',
    code: '',
    description: '',
    exit: false,
})

const gdsList = props.GDSList;
const codeTypesList = props.codeTypes;

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    form[key] = option;
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            gds_id: data.gds_id && data.gds_id.id ? data.gds_id.id : data.gds_id,
            code_type: data.code_type && data.code_type.id ? data.code_type.id : data.code_type,
        }))
        .post('/admin/statuses/store', {
            preserveScroll: true,
            onSuccess: () => form.reset(),
        })
}

</script>

<template>

    <Head title="Add status" />

    <Layout>

        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/statuses">Statuses</a>
                </li>
                <li class="breadcrumb-item">
                    Add status
                </li>
            </ol>
        </template>

        <template v-slot:title>Statuses</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Add status</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <select-custom label="GDS"
                                                       required
                                                       @select-option="(e)=>handleSelect(e,'gds_id')"
                                                       :value="form.gds_id"
                                                       :options="gdsList"
                                                       :hint="form.errors.gds_id"
                                                       name="gds_id"
                                                       placeholder="Select GDS"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <select-custom label="Code type"
                                                       required
                                                       @select-option="(e)=>handleSelect(e,'code_type')"
                                                       :value="form.code_type"
                                                       :options="codeTypesList"
                                                       :hint="form.errors.code_type"
                                                       name="code_type"
                                                       placeholder="Select code type"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <input-simple
                                            label="Code"
                                            name="code"
                                            required
                                            placeholder="Enter status code"
                                            v-model="form.code"
                                            :hint="form.errors.code"
                                            uppercase
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <input-simple
                                            label="Description"
                                            name="description"
                                            required
                                            placeholder="Enter description"
                                            v-model="form.description"
                                            :hint="form.errors.description"
                                        ></input-simple>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <button type="button" class="btn btn-alt-secondary mb-3 mb-sm-0 me-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button" class="btn btn-primary" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
