<template>
    <div class="form-group"
        :class="hint ? 'has-error' : '' "
    >
<!--        :class="hint ? 'error' : '' "-->
<!--        :class="{hint: 'error'}"-->
<!--        :class="[{hint: 'error'}, {}]">-->
        <label
            v-if="label"
            :for="name"
            class="form-label"
            :class="disabled ? 'disabled' : ''"
        >
            {{ label}}
            <span v-if="required" class="form-required text-danger">*</span>
            <span v-if="lang" class="form-badge">{{ lang }}</span>
        </label>
        <textarea class="form-control" required="required"
               :maxlength="maxlength"
               :name="name"
               :id="name"
               :placeholder="placeholder"
               @input="updateInput"
               :required="required"
               :disabled="disabled"
               :value="modelValue"
        >{{ modelValue }}</textarea>
        <div class="form-hint" v-if="hint">
            {{ hint }}
        </div>
    </div>
</template>

<!--v-model.number="inputValue"-->
<!--@input="filterInput"-->
<!--data() {-->
<!--return {-->
<!--inputValue: ''-->
<!--};-->
<!--},-->

<!--methods: {-->
<!--filterInput(event) {-->
<!--//-->
<!--// this.modelValue = event.target.value.replace(/\D/g, '');-->
<!--this.inputValue = event.target.value.replace(/\D/g, '');-->

<!--// console.log('filterInput: ', this.inputValue);-->
<!--this.$emit('update:modelValue', this.inputValue);-->
<!--}-->
<!--}-->

<script>
export default {
    name: "TextareaSimple",
    props: {
        label: {
            type: String,
        },
        lang: {
            type: String,
        },
        name: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        hint: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        maxlength: {
            type: Number,
            default: 5000,
        },
        modelValue: {
            type: [String, Number],
            default: "",
        },
        uppercase: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        updateInput(event) {
            if (this.uppercase) {
                this.$emit('update:modelValue', event.target.value.toUpperCase());
            } else {
                this.$emit('update:modelValue', event.target.value);
            }
        }
    },
    // mounted() {
    //     // this.isDisabled = this.disable;
    //     // console.log('isDisabled: ', this.isDisabled)
    // }
}
</script>

<style>

</style>
