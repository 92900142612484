<template>
    <div class="form-group"
         :class="hint ? 'has-error' : '' "
    >
        <label
            v-if="label"
            class="form-label"
        >
            {{ label }}
        </label>
                <v-autocomplete
                    ref="autocomplete"
                    v-model="selectedAirports"
                    :items="airports"
                    chips
                    focused
                    :loading="loading"
                    closable-chips
                    item-title="name"
                    item-value="name"
                    multiple
                    :search="search"
                    clear-on-select
                    flat
                    inputmode="text"
                    @input="debouncedFetchItems"
                    variant="outlined"
                >
                    <template v-slot:chip="{ props, item }">
                        <v-chip
                            label
                            color="black"
                            v-bind="props"
                            :text="item.raw.iata ?? item.raw.title.en ?? item.raw.title"
                        ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            :subtitle="item.raw.title"
                            :title="item.raw.iata"
                        ></v-list-item>
                    </template>
                </v-autocomplete>
    </div>
</template>

<script>
import axios from 'axios'
import {compact, debounce} from "lodash";
import {ref} from "vue";

export default {
    props: {
        modelValue: {type : Array, default: ()=> []},
        label: {type: String, default: 'Nearby Airports'},
        hint: {
            type: String,
        },
    },
    data() {
        return {
            selectedAirports: [],
            airports: [
            ],
            search : '',
            isUpdating: false,
            autocompleteKey: 0,
            loading: false
        }
    },
    created() {
        this.debouncedFetchItems = debounce(this.fetchItems, 1000);
    },
    mounted() {
        // this.$refs.autocomplete.isFocused = true;
    },
    emits: ['update:modelValue'],
    watch: {
        search: {
           handler(newValue) {
               console.log('val', newValue)
           }
        },
        modelValue: {
            immediate: true,
            handler(newValue) {
                this.selectedAirports = newValue;
            }
        },
        selectedAirports(newVal) {
            console.log('here', newVal);
            this.$emit('update:modelValue', newVal);
        },
        airports(newValue) {
                this.$refs.autocomplete.isFocused = true;
                // this.$refs.autocomplete.isFocused = true;
                this.$refs.autocomplete.isMenuActive = true;

        },

    },

    methods: {
        fetchItems(search) {
            this.loading = true
            this.$refs.autocomplete.isMenuActive = false;
            this.$refs.autocomplete.isFocused = false;
            if (!search.target.value) return
            const currentUrl = window.location.origin;
            // Здесь указывается ваш URL API и параметры запроса, если необходимы
            axios.post(`${currentUrl}/api/get/airports/iata-or-name`, {
                iata: search.target.value
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    this.airports = response.data.data;
                })
                .catch(error => {
                    console.error("Error fetching airports:", error);
                    this.airports = [];
                }).finally(()=> {this.loading = false});
        },
    },
}
</script>
<style lang="scss">
</style>


