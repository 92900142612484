<script setup>

import { Head, useForm } from '@inertiajs/vue3'
import { computed, ref } from "vue";

import Layout from '../Layout.vue'
import SelectCustom from "../../components/UI/SelectCustom.vue";
import InputTags from "../../components/UI/InputTags.vue";

const props = defineProps({
    airlines: Object,
});

const form = useForm({
    airline_id: '',
    economy: [],
    basic_economy: [],
    premium_economy: [],
    business: [],
    first: [],
    exit: false,
})

const searchAirlineString = ref('');

const searchAirline = (value) => {
    searchAirlineString.value = value;
}

const handleInput = (id, key) => {
    form[key] = id;
}

const handleCheckboxButtonInput = (value, key) => {
    form[key] = value
}

const handleSelect = (option, key) => {
    form[key] = option;
}

const airlinesList = computed(() => {
    return props.airlines.filter(airline => airline.title.toLowerCase().includes(searchAirlineString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            airline_id: data.airline_id && data.airline_id.id ? data.airline_id.id : data.airline_id,
        }))
        .post('/admin/booking-classes/store' , {
            preserveScroll: true,
            onSuccess: () => form.reset(),
        })
}

</script>

<template>

    <Head title="Add booking class" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/booking-classes">Booking classes</a>
                </li>
                <li class="breadcrumb-item">
                    Add booking class
                </li>
            </ol>
        </template>

        <template v-slot:title>Booking classes</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Add booking class</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <select-custom label="Airline"
                                                       required
                                                       enableSearch
                                                       @select-option="(e)=>handleSelect(e,'airline_id')"
                                                       :value="form.airline_id"
                                                       :options="airlinesList"
                                                       :hint="form.errors.airline_id"
                                                       @search="searchAirline"
                                                       name="airline_id"
                                                       placeholder="Select airline"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-tags label="Economy"
                                                    name="economy"
                                                    :hint="form.errors.economy"
                                                    v-model="form.economy"
                                                    uppercase
                                        />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-tags label="Basic economy"
                                                    name="basic_economy"
                                                    :hint="form.errors.basic_economy"
                                                    v-model="form.basic_economy"
                                        />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-tags label="Premium Economy"
                                                    name="premium_economy"
                                                    :hint="form.errors.premium_economy"
                                                    v-model="form.premium_economy"
                                        />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-tags label="Business"
                                                    name="business"
                                                    :hint="form.errors.business"
                                                    v-model="form.business"
                                        />
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-tags label="First"
                                                    name="first"
                                                    :hint="form.errors.first"
                                                    v-model="form.first"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <button type="button" class="btn btn-alt-secondary mb-3 mb-sm-0 me-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button" class="btn btn-primary" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
