<script setup>

import { Head, router, useForm } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import Published from "../../components/UI/Published.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";
import InputTags from "../../components/UI/InputTags.vue";
import DiapasonInput from "../../components/UI/DiapasonInput.vue";
import InputTagsSelect from "../../components/UI/InputTagsSelect.vue";

const props = defineProps({
    resource: Object,
    actions: {
        type: Array,
        default: () => []
    },
});

const resource = props.resource;

const form = useForm({
    risk_level : resource.risk_level ?? '',
    dep_days_start : resource.dep_days_start ?? '',
    dep_days_end : resource.dep_days_end ?? '',
    trip_type : JSON.parse(resource.trip_type) ?? [],
    title: resource.title,
    cost: resource.cost,
    min_mark_up: resource.min_mark_up,
    published: resource.published,
    exit: false,
})

const handleInput = (id, key) => {
    form[key] = id;
}

const handleCheckboxButtonInput = (value, key) => {
    form[key] = value
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form.dep_days_end = form.dep_days_end.toString();
    form.dep_days_start = form.dep_days_start.toString();
    form
        .put('/admin/mileages/update/' + resource.id , {
            preserveScroll: true,
            // preserveState: false,
            onSuccess: () => {
                router.reload({ only: ['form'] }) // при сохранении без выходя обновляем данные в форме
            },
        })
}

const deleteItem = () => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/mileages/destroy/' + resource.id, {
            preserveScroll: true,
        })
    }
}

const handleSelect = (option, key) => {
    form[key] = option;
}

</script>

<template>

    <Head title="Edit mileage program" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/mileages">Mileage programs</a>
                </li>
                <li class="breadcrumb-item">
                    Edit mileage program
                </li>
            </ol>
        </template>

        <template v-slot:title>Mileage programs</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Edit mileage program</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Program title"
                                            name="title"
                                            required
                                            placeholder="Enter program title"
                                            v-model="form.title"
                                            :hint="form.errors.title"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Mile cost, $"
                                            name="cost"
                                            required
                                            placeholder="Enter mile cost"
                                            v-model="form.cost"
                                            :hint="form.errors.cost"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Min. mark-up, $"
                                            name="min_mark_up"
                                            required
                                            placeholder="Enter min. mark-up"
                                            v-model="form.min_mark_up"
                                            :hint="form.errors.min_mark_up"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <select-custom label="Risk level"
                                                       required
                                                       @select-option="(e)=>handleSelect(e,'risk_level')"
                                                       :value="form.risk_level"
                                                       :options="['Low', 'High']"
                                                       :hint="form.errors.risk_level"
                                                       name="country_id"
                                                       placeholder="Select country"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                            <input-tags-select label="Trip type"
                                                        name="trip_type"
                                                        :hint="form.errors.trip_type"
                                                        v-model="form.trip_type"
                                            />
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                            <p class="form-label" style="padding: 0!important; margin-bottom: 4px">Departure restrictions, days</p>
                                        <div style="display:flex;">
                                            <input-simple
                                                style="margin-right: 12px"
                                                name="min_mark_up"
                                                placeholder="Enter start day"
                                                v-model="form.dep_days_start"
                                                :hint="form.errors.dep_days_start"
                                            ></input-simple>
                                            <input-simple
                                                name="min_mark_up"
                                                placeholder="Enter end day"
                                                v-model="form.dep_days_end"
                                                :hint="form.errors.dep_days_end"
                                            ></input-simple>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <published
                                            required
                                            name="published"
                                            :option="resource.published"
                                            @changeCheckbox="(e)=>handleCheckboxButtonInput(e,'published')"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Delete')||actions.includes('Update')">
            <button type="button"
                    v-if="actions.includes('Delete')"
                    class="btn btn-outline-danger mb-3 mb-sm-0" @click="deleteItem">Delete mileage program</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-alt-secondary mb-3 mb-sm-0 ms-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-primary ms-sm-3" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
