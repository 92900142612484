<template>
    <div class="form-group"
         :class="[hint ? 'has-error' : '',  hintDropzone ? 'has-error' : '' ]"
    >
        <label
            v-if="label"
            :for="name"
            class="form-label"
            :class="[ disabled, $slots.checkbox ? 'with-checkbox' : '' ]"
        >
            {{ label}}
            <span v-if="required" class="form-required text-danger">*</span>
            <span v-if="lang" class="form-badge">{{ lang }}</span>

            <slot name="checkbox"></slot>

        </label>
        <div ref="dropzone" class="dropzone" :id="name"></div>
        <div class="form-hint" v-if="hint">
            {{ hint }}
        </div>
        <div class="form-hint" v-if="hintDropzone">
            {{ hintDropzone }}
        </div>
    </div>
</template>

<script>

import Dropzone from "dropzone";

export default {
    name: "Dropzone",
    props: {
        label: {
            type: String,
        },
        name: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        lang: {
            type: String,
        },
        hint: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        maxFiles: {
            type: Number,
            default: 1
        },
        files: {
            type: Array,
            default: () => []
        },
        existing_files: {
            type: Array,
            default: () => []
        },
    },

    data() {
        return {
            dropzone: null,
            hintDropzone: ''
        }
    },

    mounted() {
        const thisElm = this;
        const files = this.files
        const existing_files = this.existing_files
        const max_files = parseInt(this.maxFiles);

        const dropzone = new Dropzone(this.$refs.dropzone,{
            url: "/drop-zone/store-file",
            method: "post",
            maxFilesize: 2, // MB
            maxFiles: max_files,
            addRemoveLinks: true,
            dictDefaultMessage: "Drop media here, or upload",
            acceptedFiles: '.jpg,.jpeg,.png, .svg',
            headers: {
                "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]').content
            },
            removedfile: file => {
                let index = this.files.findIndex(item => item.includes(file.name));
                file.previewElement.remove();
                files.splice(index, 1);
                thisElm.$emit('imagesChanger', files);

                if (files.length <= max_files) thisElm.hintDropzone = '';
                if (files.length === 0) dropzone.removeAllFiles();
            },
            maxfilesexceeded: function(file, response) {
                thisElm.hintDropzone = "You can't upload any more files.";
                file.previewElement.remove();
            },
            success: (file, response) => {
                files.push(response.name);
                thisElm.$emit('imagesChanger', files);
                thisElm.hintDropzone = '';
            },
            // error: (file, message) => {
            //     console.log('error1: ', message)
            //     // file.previewElement.remove();
            //     // thisElm.hintDropzone = response.errors.file[0];
            // },
            init: function() {
                let myDropzone = this;
                const { displayExistingFile } = myDropzone;
                myDropzone.displayExistingFile = function (mockFile, imageUrl, callback, crossOrigin, param) {
                    displayExistingFile.call(this, mockFile, imageUrl, callback, crossOrigin, param);
                    mockFile.accepted = true;
                    mockFile.status = Dropzone.SUCCESS;
                    this.files.push(mockFile);
                    this._updateMaxFilesReachedClass();
                };
                existing_files.forEach(file => {
                    let mockFile = { name: file.name, size: file.size };
                    myDropzone.displayExistingFile(mockFile, file.path);
                    files.push(file.name);
                    thisElm.$emit('imagesChanger', files);
                });
                this.on('error', function(file, errorMessage) {
                    // console.log('error2: ', errorMessage)
                    file.previewElement.remove();
                    dropzone.removeAllFiles();
                    thisElm.hintDropzone = errorMessage.toString();
                });
            }
        });
    },
};
</script>

<style lang="sass">

@import "dropzone/dist/dropzone.css"

.dropzone
    min-height: 108px
    border: 2px dashed var(--gray-bg-gray)
    border-radius: 5px
    padding: 19px
    transition: .2s ease-out
    .dz-message
        margin: 53px 0
        font-style: normal
        //font-weight: 400
        color: black
        .dz-button
            color: var(--primary-bg-primary-dark)
    .dz-preview
        margin: 5px
        &:hover
            .dz-remove
                opacity: 1

        //.dz-progress
        //    display: none
        &.dz-image-preview
            background-color: transparent

        .dz-image
            border-radius: 12px
            background-color: var(--primary-bg-primary-darker)
            //width: 78px
            //height: 60px
            img
                width: 100%
                height: 100%
                object-fit: cover
                opacity: 0.75
                transition: .2s ease-out
        //.dz-details
        //    display: none

        .dz-success-mark, .dz-error-mark
            margin-left: -20px
            margin-top: -20px
            svg
                width: 40px
                height: 40px

        .dz-remove
            position: absolute
            z-index: 100
            top: -6px
            right: -6px
            width: 24px
            height: 24px
            cursor: pointer
            border-radius: 50%
            text-indent: -99999px
            background-color: white
            background-repeat: no-repeat
            background-position: 0 0
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Ccircle%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%2212%22%20fill%3D%22%23fff%22%2F%3E%0A%20%20%3Cpath%20d%3D%22M12%2024a12%2012%200%201%200%200-24%2012%2012%200%200%200%200%2024ZM8.2%208.2a1.12%201.12%200%200%201%201.6%200l2.2%202.2%202.2-2.2a1.12%201.12%200%200%201%201.59%201.59l-2.2%202.2%202.2%202.2c.44.45.44%201.16%200%201.6-.44.43-1.16.44-1.6%200l-2.2-2.2-2.2%202.2A1.12%201.12%200%200%201%208.2%2014.2l2.2-2.2-2.2-2.2a1.12%201.12%200%200%201%200-1.6Z%22%20fill%3D%22%23DC2626%22%2F%3E%0A%3C%2Fsvg%3E%0A")


</style>
