<script setup>

const props = defineProps({
    column: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    direction: {
        type: String,
        // required: true
    },
})

const handleClick = () => {
    this.$emit('sortColumn', props.column);
}

</script>

<template>
    <span class="table__sorting_trigger"
          :class="direction ? 'active' : '' "
          @click="$emit('sortColumn', props.column);"
    >
        {{ title }}
        <span class="table__sorting_icon">
            <i class="fa fa-fw fa-sort-asc" v-if="direction == 'asc'"></i>
            <i class="fa fa-fw fa-sort-desc" v-else-if="direction == 'desc'"></i>
            <i class="fa fa-fw fa-sort" v-else></i>
        </span>
    </span>
</template>

<style scoped>

</style>
