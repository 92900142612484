<template>
    <div class="form-group custom-select"
         :class="hint ? 'has-error' : '' "
    >
        <label
            v-if="label"
            :for="name"
            class="form-label"
            :class="{disabled: disabled, required: required}"
        >
            {{ label }}
            <span v-if="required" class="form-required text-danger">*</span>
        </label>

        <div class="form-select"
             :class="{disabled: disabled, 'has-value': getSelectedValue.length}"
             :name="name"
             type="text"
             :id="name"
             @click.prevent="toggleDropdown"
        >
            <div class="custom-select-content" v-if="getSelectedValue.length">
                <span class="custom-select-tag" v-for="item in getSelectedValue">
                    {{ item.title }}
                    <span class="custom-select-tag-remove" @click.prevent.stop="removeSelectedValue(item)"></span>
                </span>
            </div>
            <template v-else>
                {{ placeholder }}
            </template>

        </div>

        <div class="custom-select-dropdown"
             v-if="isDropdownOpen"
             :class="{'with-search': enableSearch}"
        >
            <template v-if="!$slots?.dropDown">
                <div class="custom-select-dropdown-header" v-if="enableSearch">
                    <input v-focus v-model="searchString" :name="'search_' +name" type="text" class="form-control" @input="updateSearchInput" placeholder="Search...">
                </div>
                <div class="custom-select-dropdown-list" v-if="options.length > 0">
                    <div class="custom-select-dropdown-item"
                         v-for="option in options"
                         @click="() => selectOption( option )"
                         :class="{ active: isSelected( option ) }"
                    >{{ getOptionTitle(option) }}</div>
                </div>
                <div class="custom-select-dropdown-empty-list" v-else>
                    Nothing found
                </div>
            </template>

            <slot name="dropDown" :options="options" :selectHandle="selectOption"></slot>
        </div>

        <div class="form-hint" v-if="hint">
            {{ hint }}
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectTagCustom",
    props: {
        options: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
        },
        name: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        hint: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
        },
        enableSearch: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Object, String, Number],
        }
    },
    computed: {
        getSelectedValue() {
            return this.value ?? [];
        }
    },
    data() {
        return {
            isDropdownOpen: false,
            selectedOption: [],
            searchString: '',
        };
    },
    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        updateSearchInput(event) {
            // console.log('event.target.value: ', event.target.value)
            this.$emit('search', event.target.value);
        },

        selectOption(option) {
            const index = this.selectedOption.findIndex(element => element?.id == option?.id);
            if (index > -1) {
                this.selectedOption.splice(index, 1);
            } else {
                this.selectedOption.push(option);
            }
            this.$emit('select', this.selectedOption);
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        removeSelectedValue(option) {
            this.isDropdownOpen = false;
            // console.log('this.value2: ', this.value)

            const index = this.selectedOption.findIndex(element => element?.id == option?.id);
            this.selectedOption.splice(index, 1);
        },
        isSelected(option) {
            if (option?.id) {
                const index = this.selectedOption.findIndex(element => element?.id == option?.id);
                return index > -1;

            } else {
                return this.selectedOption == option;
            }
        },
        getOptionTitle(option) {
            if (option?.id) {
                return option.title?.en ?? option.title
            } else {
                return option
            }
        }
    },
    beforeMount() {
        if (this.value) {
            this.selectedOption = this.value;
        }
    },
    mounted() {
        document.addEventListener('click', (event) => {
            if (!this.$el.contains(event.target)) {
                this.isDropdownOpen = false;
            }
        });
    },
    beforeDestroy() {
        document.removeEventListener('click', () => {
            this.isDropdownOpen = false;
        });
    }
}
</script>

<style scoped lang="sass">


</style>
