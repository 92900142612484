<template>
    <ul class="nav nav-tabs nav-tabs-block">
        <li class="nav-item" v-for="tab in tabs" :key="tab">
            <button class="nav-link"
                    :class="{ 'active': tab.isActive, 'tab-with-error': tab.hint }"
                    @click="selectTab(tab)"
                    type="button"
            >
                {{ tab.title }}
<!--                <span class="text-danger" v-if="tab.hint">*</span>-->
            </button>
        </li>
    </ul>
    <div class="block-content block-content-full">
        <slot></slot>
    </div>
</template>

<script>
export default {
    data() {
        return { tabs: [] }
    },
    created() {
        // this.tabs = this.$children;
    },
    methods: {
        selectTab: function(selectedTab) {
            this.tabs.forEach(tab => {
                tab.isActive = (tab.title == selectedTab.title);
            });
        }
    },
}
</script>

<style scoped>
.tab-with-error {
    color: white;
    background-color: var(--contextual-bg-danger);
}
</style>
