<script setup>

import { useNotification } from "@kyvg/vue3-notification";
import { usePage } from '@inertiajs/vue3';
import { nextTick, watch } from "vue";

const page = usePage();
const notification = useNotification();

watch(() => page.props.message, async (value) => {
    if (value) {
        await nextTick();
        notification.notify({
            type: page.props.status,
            text: value,
        });
        page.props.message = null;
    }
}, { immediate: true })

</script>

<template>
    <div id="page-container">
        <!-- Main Container -->
        <main id="main-container" class="m-0">

            <!-- Page Content -->
            <div class="bg-image" style="background-image: url('/media/admin/favicons/PrivyTripsLogo.png');">
<!--                <div class="bg-image" style="background-image: url('{{ asset('media/admin/main-bg.jpg') }}');">-->
                <div class="row g-0 bg-primary-dark-op">

                    <!-- Info Section -->
                    <div class="hero-static col-lg-4 d-none d-lg-flex flex-column justify-content-center">
                        <div class="p-4 p-xl-5 flex-grow-1 d-flex align-items-center">
                            <div class="w-100">
                                <p class="link-fx fw-semibold fs-2 text-white">
                                    Amigo
                                    <span class="fw-normal">CMS</span>
                                </p>
                                <p class="text-white-50 me-xl-8 mt-2 ">
                                    Welcome to the content management system!<br>
                                    Please login to access it.
                                </p>
                            </div>
                        </div>
                        <div class="p-4 p-xl-5 d-xl-flex justify-content-between align-items-center fs-sm">
                            <p class="fw-medium text-white-50 mb-0">
                                CMS by <a class="text-white-50 text-decoration-underline" href="https://amigo.studio/ro" target="_blank">amigo.studio</a>
                            </p>
                        </div>
                    </div><!-- END Info Section -->

                    <!-- Main Section -->
                    <div class="hero-static col-lg-8 d-flex flex-column align-items-center bg-body-light">
                        <div class="p-3 w-100 d-lg-none text-center">
                            <p class="link-fx fw-semibold fs-2 text-dark">
                                Amigo
                                <span class="fw-normal">CMS</span>
                            </p>
                        </div>

                        <!-- Content -->
                        <div class="p-4 w-100 flex-grow-1 d-flex align-items-center">
                            <div class="w-100">
                                <slot name="content"></slot>
                            </div>
                        </div><!-- END Content -->

                        <!-- Version -->
<!--                        <div class="px-4 py-3 w-100 d-lg-none d-flex flex-column flex-sm-row justify-content-between fs-sm text-center text-sm-start">-->
<!--                            <p class="fw-medium text-black-50 py-2 mb-0">-->
<!--                                <strong>AmigoCMS v{{ $version }}</strong> &copy;-->
<!--                                <span data-toggle="year-copy"></span>-->
<!--                            </p>-->
<!--                        </div>-->
                        <!-- END Version -->

                    </div><!-- END Main Section -->
                </div>
            </div><!-- END Page Content -->
        </main><!-- END Main Container -->

    </div><!-- END Page Container -->
    <notifications position="bottom center" width="400"/>

</template>
