<template>
    <div class="form-group custom-select"
         :class="hint ? 'has-error' : '' "
    >
        <label
            v-if="label"
            :for="name"
            class="form-label"
            :class="{disabled: disabled && !value, required: required}"
        >
            {{ label }}
            <span v-if="required" class="form-required text-danger">*</span>
        </label>
        <div class="custom-select">
                <div class="form-select"
                     :class="{disabled: disabled && !value, 'has-value': getSelectedValue}"
                     :name="name"
                     type="text"
                     :id="name"
                     :title="getSelectedValue ? getSelectedValue : placeholder"
                     @click.prevent="toggleDropdown"
                >{{ getSelectedValue ? getSelectedValue : placeholder }}
            </div>

            <div class="custom-select-dropdown"
                 v-if="isDropdownOpen"
                 :class="getClassList" ref="dropdownMenu"
            >
                <template v-if="!$slots?.dropDown">
                    <div class="custom-select-dropdown-header" v-if="enableSearch">
                        <input v-focus
                               v-model="searchString"
                               :name="'search_' +name"
                               type="text"
                               class="form-control"
                               @input="updateSearchInput"
                               placeholder="Search...">
                    </div>
                    <div class="custom-select-dropdown-list" v-if="options.length > 0">
                        <div class="custom-select-dropdown-item"
                             v-for="option in options"
                             @click="() => selectOption( option )"
                             :class="{ active: isSelected( option ) }"
                        >{{ getOptionTitle(option) }}</div>
                    </div>
                    <div class="custom-select-dropdown-empty-list" v-else>
                        Nothing found
                    </div>
                </template>

                <slot name="dropDown" :options="options" :selectHandle="selectOption"></slot>

            </div>
        </div>

        <div class="form-hint" v-if="hint">
            {{ hint }}
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectCustom",
    props: {
        options: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
        },
        name: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        hint: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
        },
        enableSearch: {
            type: Boolean,
            default: false,
        },
        largeWidth: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Object, String, Number],
        }
    },
    computed: {
        getSelectedValue() {

            if (typeof this.value === 'string' || typeof this.value === 'number') return this.value;
            if (typeof this.value?.title === 'string' || typeof this.value?.title === 'number') {
                return this.value?.title;
            } else {
                return this.value?.title?.en ?? "";
            }

            // if (option?.id) {
            //     return this.selectedOption?.id == option?.id
            // } else {
            //     return this.selectedOption == option;
            // }

        },
        getClassList() {
            return {
                'with-search': this.enableSearch,
                'dropdown-bottom': this.isDropdownBelow,
                'dropdown-top': !this.isDropdownBelow,
                'large-width': this.largeWidth,
            }
        }
    },
    data() {
        return {
            isDropdownOpen: false,
            selectedOption: null,
            isDropdownBelow: true,
            searchString: '',
        };
    },
    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        updateSearchInput(event) {
            // console.log('updateSearchInput', event.target.value)
            this.$emit('search', event.target.value);
        },
        selectOption(option) {
            this.$emit('select-option', option);
            this.isDropdownOpen = !this.isDropdownOpen;
            return this.selectedOption = option;
        },
        toggleDropdown() {
            const thisElmBound = this.$el.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            const thisElmY = thisElmBound.y;
            const thisElmHeight = thisElmBound.height;
            const searchHeight = this.enableSearch ? 62 : 8;
            const dropdownHeight = this.options.length >= 8 ? 8 * 36 + searchHeight + 8 : this.options.length * 36 + searchHeight + 8;

            thisElmY + thisElmHeight + dropdownHeight > windowHeight - 8 ? this.isDropdownBelow = false : this.isDropdownBelow = true;
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        isSelected(option) {
            if (option?.id) {
                return this.selectedOption?.id == option?.id
            } else {
                return this.selectedOption == option;
            }
        },
        getOptionTitle(option) {
            if (option?.id) {
                return option.title?.en ?? option.title
            } else {
                return option
            }
        }
    },
    beforeMount() {
        if (this.value) {
            this.selectedOption = this.value;
        }
    },
    watch: {
        value: function (val) {
            this.selectedOption = val;
        },
    },
    mounted() {
        document.addEventListener('click', (event) => {
            if (!this.$el.contains(event.target)) {
                this.isDropdownOpen = false;
            }
        });
        let modal = document.querySelector('.modal-content');
        if (modal) {
            modal.addEventListener('click', (event) => {
                if (!this.$el.contains(event.target)) {
                    this.isDropdownOpen = false;
                }
            });
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', () => {
            this.isDropdownOpen = false;
        });
        let modal = document.querySelector('.modal-content');
        if (modal) {
            modal.removeEventListener('click', (event) => {
                if (!this.$el.contains(event.target)) {
                    this.isDropdownOpen = false;
                }
            });
        }
    }
}
</script>

<style scoped lang="sass">

</style>
