<script setup>

import { computed, ref } from "vue";
import axios from "axios";
import { Head, useForm, Link, router } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";
import InputTags from "../../components/UI/InputTags.vue";
import Tabs from "../../components/UI/Tabs.vue";
import Tab from "../../components/UI/Tab.vue";
import RadioButtonsYesNo from "../../components/UI/RadioButtonsYesNo.vue";
import SelectPhoneCode from "../../components/UI/SelectPhoneCode.vue";
import Published from "../../components/UI/Published.vue";

const props = defineProps({
    resource: Object,
    errorTitle: Object,
    countries: Object,
    states: Object,
    cities: Object,
    timezones: Object,
    dialCodes: Object,
    airportTypes: Object,
    utcList: Object,
    languages: Object,
    actions: {
        type: Array,
        default: () => []
    },
});

const resource = props.resource;
const airportTypesList = props.airportTypes;
const statesList = ref(props.states);
const cities = ref(props.cities);
const timezonesList = ref(props.timezones);
const utcList = ref(props.utcList);

const searchCountryString = ref('');
const searchDialString = ref('');
const searchCityString = ref('');

const priorityList = [
    { id: 1, title: '1' },
    { id: 2, title: '2' },
    { id: 3, title: '3' },
    { id: 4, title: '4' },
    { id: 5, title: '5' },
];

const form = useForm({
    title: resource.title,
    iata: resource.iata,
    type_id: resource.airport_type,
    country_id: resource.country,
    state_id: resource.state,
    state_exist: !!resource?.state || statesList.value.length > 0,
    city_id: resource.city,
    priority: priorityList[resource.priority - 1],
    faa: resource.faa,
    international: resource.international,
    major: resource.major,
    popularity: resource.popularity,
    connections: resource.connections,
    departures: resource.departures,
    runways: resource.runways,
    latitude: resource.latitude,
    longitude: resource.longitude,
    altitude: resource.altitude,
    timezone_id: resource.timezone,
    utc: resource.utc,
    dst: resource.dst,
    address: resource.address,
    zip: resource.zip,
    website: resource.website,
    email: resource.email,
    dial_code_id: resource.dial_code,
    phone: resource.phone,
    error_title: props.errorTitle,
    published: resource.published,
    resource_id: resource.id,
    exit: false,
})

const findLanguage = (code, array) => {
    return array.find((item) => item.code == code).title;
}

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    form[key] = option;
    selectRequest(key, option);
}

const storeFiles = (files) => {
    form.image = files;
}

const handleChangeTag = (tags) => {
    form.error_title = tags;
}

const searchCountry = (value) => {
    searchCountryString.value = value;
}

const searchCity = (value) => {
    searchCityString.value = value;
}

const searchDial = (value) => {
    searchDialString.value = value;
}

const handleRadioButtonInput = (value, key) => {
    form[key] = value == 'true' ? true : false
}

const handleCheckboxButtonInput = (value, key) => {
    form[key] = value
}

const isCountrySelected = computed(() => !!form.country_id && props.states.length > 0)
const isStateSelected = computed(() => !!form.country_id && cities.value.length > 0)
const isStateSelected2 = computed(() => !!form.country_id && timezonesList.value.length > 0)

const countriesList = computed(() => {
    return props.countries.filter(country => country.title.toLowerCase().includes(searchCountryString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

const citiesList = computed(() => {
    return cities.value.filter(city => city.title.toLowerCase().includes(searchCityString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

const dialCodes = computed(() => {
    return props.dialCodes.filter(dialCode => {
            let dialCodeString = dialCode.code.toString();
            let searchDialStringWithoutPlus = searchDialString.value.replace('+', '');
            return dialCode.title.toLowerCase().includes(searchDialString.value.toLowerCase()) || dialCodeString.toLowerCase().includes(searchDialStringWithoutPlus.toLowerCase())
        }

    ).map((item) => {
        return {
            title: item.title,
            id: item.id,
            code: item.code,
            image: item.image
        }
    })
});

const selectRequest = (key, option) => {
    if (key === 'country_id') {
        statesList.value = [];
        citiesList.value = [];
        searchCityString.value = '';
        timezonesList.value = [];
        form.state_id = '';
        form.city_id = '';
        form.timezone_id = '';
        axios
            .post('/admin/states/search/list', {
                country_id: option.id,
            })
            .then((response) => {
                if (response.data.data.length > 0) {
                    statesList.value = response.data.data;
                    form.state_exist = true;
                } else {
                    form.state_exist = false;
                }
            })

        axios
            .post('/admin/timezones/search/list', {
                country_id: option.id,
            })
            .then((response) => {
                if (response.data.data.length > 0) timezonesList.value = response.data.data;
            })
        axios
            .post('/admin/cities/search/list', {
                country_id: option.id,
            })
            .then((response) => {
                if (response.data.data.length > 0) cities.value = response.data.data;
            })
    }


    if (key === 'state_id') {
        citiesList.value = [];
        form.city_id = '';
        axios
            .post('/admin/cities/search/list', {
                state_id: option.id,
            })
            .then((response) => {
                if (response.data.data.length > 0) cities.value = response.data.data;
            })
    }
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            country_id: data.country_id && data.country_id.id ? data.country_id.id : data.country_id,
            type_id: data.type_id && data.type_id.id ? data.type_id.id : data.type_id,
            state_id: data.state_id && data.state_id.id ? data.state_id.id : data.state_id,
            city_id: data.city_id && data.city_id.id ? data.city_id.id : data.city_id,
            priority: data.priority && data.priority.id ? data.priority.id : data.priority,
            timezone_id: data.timezone_id && data.timezone_id.id ? data.timezone_id.id : data.timezone_id,
            dial_code_id: data.dial_code_id && data.dial_code_id.id ? data.dial_code_id.id : null,
            utc: data.utc ? data.utc.toString() : null,
        }))
        .put('/admin/airports/update/' + resource.id , {
            preserveScroll: true,
            // preserveState: false,
            onSuccess: () => {
                router.reload({ only: ['form'] }) // при сохранении без выходя обновляем данные в форме
            },
        })
}

const deleteItem = () => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/airports/destroy/' + resource.id, {
            preserveScroll: true,
        })
    }
}


</script>

<template>

    <Head title="Edit airport" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <Link class="link-fx"
                          href="/admin/airports">
                        Airports
                    </Link>
                </li>
                <li class="breadcrumb-item">
                    Edit airport
                </li>
            </ol>
        </template>

        <template v-slot:title>Airports</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <Tabs>
                                <Tab title="Add airport" :selected="true">
                                    <div class="row g-12">
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple
                                                label="Airport name"
                                                name="title[en]"
                                                required
                                                placeholder="Enter airport name"
                                                v-model="form.title.en"
                                                :hint="form.errors['title.en']"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="IATA"
                                                          required
                                                          name="iata"
                                                          placeholder="Enter IATA"
                                                          v-model="form.iata"
                                                          :hint="form.errors.iata"
                                                          uppercase
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="Airport type"
                                                           @select-option="(e)=>handleSelect(e,'type_id')"
                                                           :value="form.type_id"
                                                           :options="airportTypesList"
                                                           :hint="form.errors.type_id"
                                                           name="type_id"
                                                           placeholder="Select airport type"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="Country"
                                                           required
                                                           enableSearch
                                                           @select-option="(e)=>handleSelect(e,'country_id')"
                                                           :value="form.country_id"
                                                           :options="countriesList"
                                                           :hint="form.errors.country_id"
                                                           @search="searchCountry"
                                                           name="country_id"
                                                           placeholder="Select country"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="State"
                                                           :required="form.state_exist"
                                                           :disabled="!isCountrySelected"
                                                           @select-option="(e)=>handleSelect(e,'state_id')"
                                                           :value="form.state_id"
                                                           :options="statesList"
                                                           :hint="form.errors.state_id"
                                                           name="state_id"
                                                           placeholder="Select state"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="City"
                                                           required
                                                           enableSearch
                                                           :disabled="!isStateSelected"
                                                           @select-option="(e)=>handleSelect(e,'city_id')"
                                                           :value="form.city_id"
                                                           :options="citiesList"
                                                           :hint="form.errors.city_id"
                                                           @search="searchCity"
                                                           name="city_id"
                                                           placeholder="Select city"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="Priority"
                                                           @select-option="(e)=>handleSelect(e,'priority')"
                                                           :value="form.priority"
                                                           :options="priorityList"
                                                           :hint="form.errors.priority"
                                                           name="priority"
                                                           placeholder="Select priority"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="FAA"
                                                          name="faa"
                                                          placeholder="Enter FAA"
                                                          v-model="form.faa"
                                                          :hint="form.errors.faa"
                                                          uppercase
                                            ></input-simple>
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4"></div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <radio-buttons-yes-no
                                                label="International"
                                                name="international"
                                                :option="resource.international"
                                                @changeRadio="(e)=>handleRadioButtonInput(e,'international')"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <radio-buttons-yes-no
                                                label="Major"
                                                name="major"
                                                :option="resource.major"
                                                @changeRadio="(e)=>handleRadioButtonInput(e,'major')"
                                            />
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4"></div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Popularity"
                                                          name="popularity"
                                                          placeholder="Enter popularity"
                                                          v-model="form.popularity"
                                                          :hint="form.errors.popularity"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Connections"
                                                          name="connections"
                                                          placeholder="Enter connections"
                                                          v-model="form.connections"
                                                          :hint="form.errors.connections"
                                            ></input-simple>
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-2">
                                            <input-simple label="Departures"
                                                          name="departures"
                                                          placeholder="Enter departures"
                                                          v-model="form.departures"
                                                          :hint="form.errors.departures"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-2">
                                            <input-simple label="Runways"
                                                          name="runways"
                                                          placeholder="Enter runways"
                                                          v-model="form.runways"
                                                          :hint="form.errors.runways"
                                            ></input-simple>
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Latitude"
                                                          name="latitude"
                                                          placeholder="Enter latitude"
                                                          v-model="form.latitude"
                                                          :hint="form.errors.latitude"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Longitude"
                                                          name="longitude"
                                                          placeholder="Enter longitude"
                                                          v-model="form.longitude"
                                                          :hint="form.errors.longitude"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Altitude, m"
                                                          name="altitude"
                                                          placeholder="Enter altitude"
                                                          v-model.number="form.altitude"
                                                          :hint="form.errors.altitude"
                                            ></input-simple>
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="Timezone"
                                                           :disabled="!isStateSelected2"
                                                           @select-option="(e)=>handleSelect(e,'timezone_id')"
                                                           :value="form.timezone_id"
                                                           :options="timezonesList"
                                                           :hint="form.errors.timezone_id"
                                                           name="timezone_id"
                                                           placeholder="Select timezone"
                                            ></select-custom>
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="UTC"
                                                           :disabled="!isStateSelected2"
                                                           @select-option="(e)=>handleSelect(e,'utc')"
                                                           :value="form.utc"
                                                           :options="utcList"
                                                           :hint="form.errors.utc"
                                                           name="utc_id"
                                                           placeholder="Select UTC"
                                            ></select-custom>
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <radio-buttons-yes-no
                                                label="DST"
                                                name="dst"
                                                :option="resource.dst"
                                                @changeRadio="(e)=>handleRadioButtonInput(e,'dst')"
                                                :hint="form.errors.dst"
                                            />
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Address"
                                                          name="address"
                                                          placeholder="Enter address"
                                                          v-model="form.address"
                                                          :hint="form.errors.address"
                                            ></input-simple>
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="ZIP"
                                                          name="zip"
                                                          placeholder="Enter ZIP code"
                                                          v-model="form.zip"
                                                          :hint="form.errors.zip"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4"></div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Website"
                                                          name="website"
                                                          placeholder="Add airline website"
                                                          v-model="form.website"
                                                          :hint="form.errors.website"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Email"
                                                          name="email"
                                                          placeholder="Add airline email"
                                                          v-model="form.email"
                                                          :hint="form.errors.email"
                                            ></input-simple>
                                        </div>

                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <label class="form-label required">Phone number</label>
                                            <div class="code__phone_block"
                                                 :class="{'has-error':form.errors.dial_code_id || form.errors.phone}"
                                            >
                                                <div class="d-flex align-items-center">
                                                    <select-phone-code
                                                        enableSearch
                                                        @select-option="(e)=>handleSelect(e,'dial_code_id')"
                                                        :value="form.dial_code_id"
                                                        :options="dialCodes"
                                                        @search="searchDial"
                                                        name="dial_code_id"
                                                        placeholder="&nbsp;"
                                                    >
                                                    </select-phone-code>
                                                    <input-simple
                                                        name="phone"
                                                        placeholder="XXXXXXXXXX"
                                                        v-model="form.phone"
                                                    ></input-simple>
                                                </div>
                                                <div class="form-hint" v-if="form.errors.dial_code_id">
                                                    {{ form.errors.dial_code_id }}
                                                </div>
                                                <div class="form-hint" v-if="form.errors.phone">
                                                    {{ form.errors.phone }}
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-12">
                                            <input-tags label="Syntax Errors"
                                                        name="error_title"
                                                        :hint="form.errors.error_title"
                                                        v-model="form.error_title"
                                            />
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <published
                                                name="published"
                                                :option="resource.published"
                                                @changeCheckbox="(e)=>handleCheckboxButtonInput(e,'published')"
                                            />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab title="Languages">
                                    <div class="row g-12">
                                        <template v-for="(value, key) in form.title">
                                            <div class="col-12 col-sm-6" v-if="(value !== 'None') && (key !== 'en')">
                                                <input-simple
                                                    label="Airport name"
                                                    :lang="findLanguage(key, props.languages)"
                                                    :name="'title['+[key]+']'"
                                                    placeholder="Enter airport name"
                                                    v-model="form.title[key]"
                                                ></input-simple>
                                            </div>
                                        </template>
                                    </div>
                                </Tab>
                            </Tabs>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Delete')||actions.includes('Update')">
            <button type="button"
                    v-if="actions.includes('Delete')"
                    class="btn btn-outline-danger mb-3 mb-sm-0" @click="deleteItem">Delete airport</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-alt-secondary mb-3 mb-sm-0 ms-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-primary ms-sm-3" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
