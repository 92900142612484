<script setup>

import { onMounted, ref, watch, nextTick, onBeforeMount } from "vue";
import { Link, router } from '@inertiajs/vue3'
import Cookies from "js-cookie";
import { usePage } from '@inertiajs/vue3';
import { useNotification } from "@kyvg/vue3-notification";

import SimpleBar from "simplebar";
import { Tooltip } from "bootstrap";
import SidebarNavItem from "../components/SidebarNavItem.vue";

const props = defineProps({
    collection: Object,
})

const page = usePage();
const notification = useNotification();
const darkMode = ref(false)
const sidebarMini = ref(false)
const sidebarMobileOpen = ref(false)

watch(() => page.props.message, async (value) => {
    if (value) {
        await nextTick();
        notification.notify({
            type: page.props.status,
            text: value,
        });
        page.props.message = null;
    }
}, { immediate: true })

watch(() => page.props.errors, async (value) => {
    if (Object.values(value).length > 0) {
        const errors = Object.values(value).join("<br>");
        await nextTick();
        notification.notify({
            type: 'error',
            text: errors,
        });
        page.props.message = null;
    }
}, { immediate: true })


onBeforeMount(() => {
    darkMode.value = Cookies.get('darkMode') == 'true' ? true : false
});

onMounted(() => {
    new SimpleBar(document.getElementById("simplebar-sidebar"));
    new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
        trigger: "hover",
    })
    sidebarMini.value = Cookies.get('sidebarMini') == 'true' ? true : false
});

router.on('navigate', (event) => {
    // console.log(`Navigated to ${event.detail.page.url}`)
    const confirm = document.querySelector('.confirm');
    if (confirm.classList.contains('loading')) {
        confirm.classList.remove('loading');
    }

    const tooltips = document.querySelectorAll('.tooltip');
    if (tooltips.length == 0) return;
    tooltips.forEach((tooltip) => {
        tooltip.remove();
    })

})

const toggleDarkMode = () => {
    darkMode.value = !darkMode.value;
    Cookies.set('darkMode', darkMode.value, { sameSite: 'Lax', secure: false })
}

const toggleSidebar = () => {
    sidebarMini.value = !sidebarMini.value;
    Cookies.set('sidebarMini', sidebarMini.value, { sameSite: 'Lax', secure: false })
}

const toggleMobileSidebar = () => {
    sidebarMobileOpen.value = !sidebarMobileOpen.value;
}

const ui = {
    confirm: async () => createConfirm()
}

const createConfirm = () => {
    return new Promise((complete, failed)=>{

        // const messageElm = document.querySelector('#confirmMessage');
        const confirmYes = document.querySelector('.confirm-yes');
        const confirmNo = document.querySelectorAll('.confirm-no');
        const confirm = document.querySelector('.confirm');

        // messageElm.innerHTML = message;

        // confirmYes.removeEventListener('click');
        // confirmNo.removeEventListener('click');

        confirmYes.addEventListener('click', ()=> {
            confirm.classList.remove('show');
            confirm.classList.add('loading');
            complete(true);
        });
        confirmNo.forEach((item) => {
            item.addEventListener('click', ()=> { confirm.classList.remove('show'); complete(false); });
        })

        confirm.classList.add('show');
    });
}

const logOut = async () => {
    const confirm = await ui.confirm();
    if(confirm){
        router.visit('/admin/logout',{
                // preserveState: true,
                // replace: true
                onSuccess: () => {
                    // document.querySelector('.confirm').classList.remove('loading');
                },
            },
        )
    }
}




</script>

<template>
    <div id="page-container"
         class="sidebar-o enable-page-overlay sidebar-dark side-scroll page-header-fixed remember-theme amigo-sidebar-dark side-trans-enabled"
         :class="{'page-header-dark dark-mode': darkMode, 'sidebar-mini': sidebarMini, 'sidebar-o-xs': sidebarMobileOpen }"
    >
        <nav id="sidebar" aria-label="Main Navigation">
            <div class="content-header">
                <Link class="fw-semibold text-dual" href="/admin/dashboard">
                    <span class="smini-visible">
                        <img class="fa fa-circle-notch text-primary" src="http://infohub-ticketinn.test/media/admin/favicons/favicon.png" alt="VIP Fares logo" width="16" height="16">
                    </span>
                    <span class="smini-hide fs-5 tracking-wider">VIP Fares</span>
                </Link>
                <div>
                    <button type="button" class="btn btn-sm btn-alt-secondary" data-toggle="layout" data-action="dark_mode_toggle"
                        @click="toggleDarkMode"
                    >
                        <i class="far fa-moon"></i>
                    </button>
                    <button type="button" class="d-lg-none btn btn-sm btn-alt-secondary ms-1" data-toggle="layout" data-action="sidebar_close"
                            @click="toggleMobileSidebar"
                    >
                        <i class="fa fa-fw fa-times"></i>
                    </button>
                </div>
            </div>

            <div id="simplebar-sidebar" class="js-sidebar-scroll">
                <div class="content-side">
                    <ul class="nav-main">
                        <template v-for="navItem in $page.props.global.sidebarMenu">
                            <sidebar-nav-item :item="navItem"/>
                        </template>
                    </ul>
                </div>
            </div>

            <a class="nav-main-link mt-auto" href="#" @click.prevent="logOut">
                <em class="nav-main-link-icon fa fa-arrow-right-to-bracket"></em><span class="nav-main-link-name">Logout</span>
            </a>
            <div class="content-footer">
                VIPFares v.1.0.0
            </div>
        </nav>

        <header id="page-header">
            <div class="content-header">
                <div class="d-flex align-items-center">
                    <button type="button" class="btn btn-sm btn-alt-secondary me-2 d-lg-none" data-toggle="layout"
                            data-action="sidebar_toggle"
                            @click="toggleMobileSidebar"
                    >
                        <i class="fa fa-fw fa-bars"></i>
                    </button>
                    <button type="button" class="btn btn-sm-custom btn-alt-secondary me-2 d-none d-lg-flex"
                            data-toggle="layout" data-action="sidebar_mini_toggle"
                            @click="toggleSidebar"
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.42 2.5H2.92c-.59.02-1.08.23-1.47.64-.39.4-.6.9-.62 1.5v10.72c.03.6.23 1.1.62 1.5.4.4.88.62 1.47.64h12.5a2.08 2.08 0 0 0 1.46-.64c.4-.4.6-.9.62-1.5V4.64a2.2 2.2 0 0 0-.62-1.5c-.39-.4-.88-.62-1.46-.64ZM5.26 12.68H3.7c-.48-.05-.74-.31-.78-.8.04-.5.3-.76.78-.8h1.56c.48.04.74.3.78.8-.04.49-.3.75-.78.8Zm0-3.22H3.7c-.48-.04-.74-.3-.78-.8.04-.49.3-.76.78-.8h1.56c.48.04.74.31.78.8-.04.5-.3.76-.78.8Zm0-3.21H3.7c-.48-.04-.74-.31-.78-.8.04-.5.3-.76.78-.8h1.56c.48.04.74.3.78.8-.04.49-.3.76-.78.8Zm10.68 9.1c-.02.34-.2.52-.52.54h-7.3V4.11h7.3c.32.02.5.2.52.53v10.72Z" fill="CurrentColor"/>
                        </svg>
                    </button>
                </div>

                <div class="d-flex align-items-center"> </div>

                <div class="d-flex align-items-center">
                    <slot name="buttons"></slot>
                </div>
            </div>
        </header>

        <main id="main-container">

            <div class="bg-body-light">
                <div class="content content-full py-3 px-4">
                    <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center py-2">
                        <div class="flex-grow-1 mb-1 mb-md-0">
                            <h1 class="h3 fw-bold mb-0">
                                <slot name="title"></slot>
                            </h1>
                        </div>
                        <nav class="flex-shrink-0 mt-3 mt-sm-0 ms-sm-3" aria-label="breadcrumb">
                            <slot name="breadcrumb"></slot>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="content">
                <slot name="content"></slot>
            </div>
        </main>

        <footer id="page-footer" class="bg-body-light" v-if="$slots['footer-buttons']">
            <div class="content py-3">
                <div class="row fs-sm">
                    <div class="col-12 d-flex flex-column flex-sm-row align-items-sm-center justify-content-end">
                        <slot name="footer-buttons"></slot>
                    </div>
                    <div class="col-sm-6 order-sm-1 text-center text-sm-start">
                    </div>
                </div>
            </div>
        </footer>

        <div class="confirm">
            <div class="confirm__block">
                <div class="confirm__block_header">
                    <h4 class="confirm__block_title h5">Log Out</h4>
                    <button class="confirm-no" type="button"></button>
                </div>
                <div class="confirm__block_body">
                    <div id="confirm-message">Are you sure you want to logout?</div>
                </div>
                <div class="confirm__block_footer">
                    <button class="btn btn-sm btn-outline-secondary confirm-no" type="button">Cancel</button>
                    <button class="btn btn-sm btn-danger confirm-yes" type="button">Yes, logout</button>
                </div>
            </div>
            <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                <g fill="none" fill-rule="evenodd">
                    <g transform="translate(1 1)" stroke-width="2">
                        <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="1s"
                                repeatCount="indefinite"/>
                        </path>
                    </g>
                </g>
            </svg>
        </div>

    </div>
    <notifications position="bottom center" width="400" dangerouslySetInnerHtml="true"/>

</template>
