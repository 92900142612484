<template>
    <div class="tab-content" v-show="isActive">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Tab",
    props: {
        title: { required: true },
        selected: { default: false },
        hint: { default: false }
    },
    data() {
        return {
            isActive: false
        }
    },
    created() {
        this.$parent.tabs.push(this);
    },
    mounted() {
        this.isActive = this.selected;
    }
}
</script>

<style scoped>

</style>
