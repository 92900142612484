<template>
    <li class="nav-main-item" :class="{'open': isOpen }" v-if="item.allowed">
        <Link class="nav-main-link"
              :href="item.url"
              :class="{
                'active': $page.url.includes(item.url)
              }"
              v-if="!item?.dropDown"
        >
            <em :class="['nav-main-link-icon', item.icon ]" v-if="item?.icon"></em>
            <span class="nav-main-link-name">{{ item.title }}</span>
        </Link>
        <a class="nav-main-link"
           v-else
           :class="{
                'active': $page.url.includes(item.url),
                'nav-main-link-submenu': item.dropDown
            }"
           @click.prevent="openDropDown"
           :href="`${ item.url }`">
            <em :class="['nav-main-link-icon', item.icon ]" v-if="item?.icon"></em>
            <span class="nav-main-link-name">{{ item.title }}</span>
        </a>
        <ul v-if="item?.dropDown" class="nav-main-submenu">
            <template v-for="subitem in item.dropDown">
                <sidebar-nav-item :item="subitem"/>
            </template>
        </ul>
    </li>
</template>

<script>
import { Link } from '@inertiajs/vue3'
export default {
    name: "SidebarNavItem",
    components: { Link },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        openDropDown() {
            this.isOpen = !this.isOpen;
        },
        isSubmenuOpen() {
            if (this.item?.dropDown) {
                if (this.item.dropDown.some(item => this.$page.url.includes(item.url))) {
                    // console.log('open')
                    this.isOpen = true;
                }
            } else {
                // console.log('close')
                this.isOpen = false;
            }
        }
    },
    beforeMount() {
        if (this.item?.dropDown) {
            if (this.item.dropDown.some(item => this.$page.url.includes(item.url))) {
                // console.log('open')
                this.isOpen = true;
            }
        }
    }
}
</script>

<style scoped>

</style>
