<script setup>

import { computed, ref } from 'vue';
import { Head, router, useForm } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import SelectCustom from "../../components/UI/SelectCustom.vue";
import DropzoneNew from "../../components/UI/DropZoneNew.vue";
import SelectTagCustom from "../../components/UI/SelectTagCustom.vue";

const props = defineProps({
    countries: Object,
    aircraftsList: Object,
    airlines: Object,
    resource: Object,
    images: Object,
    actions: {
        type: Array,
        default: () => []
    },
});

const resource = props.resource;

const form = useForm({
    airline_id: resource.airline,
    aircrafts: resource.aircrafts,
    images: [],
    exit: false,
})

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    form[key] = option;
}

const storeFiles = (files) => {
    form.images = files;
}

const searchAirlineString = ref('');
const searchAircraftString = ref('');

const searchAirline = (value) => {
    searchAirlineString.value = value;
}

const searchAircraft = (value) => {
    searchAircraftString.value = value;
}

const airlinesList = computed(() => {
    return props.airlines.filter(airline => airline.title.toLowerCase().includes(searchAirlineString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

const aircraftsList = computed(() => {
    return props.aircraftsList.filter(aircraft => aircraft.title.toLowerCase().includes(searchAircraftString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            airline_id: data.airline_id && data.airline_id.id ? data.airline_id.id : data.airline_id,
            aircrafts: data.aircrafts.map(item => item.id),
        }))
        .put('/admin/cabin-promos/update/' + resource.id , {
            preserveScroll: true,
            // preserveState: false,
            onSuccess: () => {
                router.reload({ only: ['form'] }) // при сохранении без выходя обновляем данные в форме
            },
        })
}

const deleteItem = () => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/cabin-promos/destroy/' + resource.id, {
            preserveScroll: true,
        })
    }
}

</script>

<template>

    <Head title="Edit cabin promo" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/cabin-promos">Cabin promo</a>
                </li>
                <li class="breadcrumb-item">
                    Edit cabin promo
                </li>
            </ol>
        </template>

        <template v-slot:title>Cabin promo</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Edit cabin promo</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6">
                                        <select-custom label="Airline"
                                                       required
                                                       enableSearch
                                                       @select-option="(e)=>handleSelect(e,'airline_id')"
                                                       :value="form.airline_id"
                                                       :options="airlinesList"
                                                       :hint="form.errors.airline_id"
                                                       @search="searchAirline"
                                                       name="airline_id"
                                                       placeholder="Select airline"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <select-tag-custom label="Aircraft"
                                                           required
                                                           enableSearch
                                                           @select-option="(e)=>handleSelect(e,'aircrafts')"
                                                           :value="form.aircrafts"
                                                           :options="aircraftsList"
                                                           :hint="form.errors.aircrafts"
                                                           @search="searchAircraft"
                                                           name="aircrafts"
                                                           placeholder="Select aircrafts"
                                        ></select-tag-custom>
                                    </div>
                                    <div class="col-12">
                                        <dropzone-new
                                            label="Media (max 5 files)"
                                            required
                                            name="images"
                                            maxFiles="5"
                                            :hint="form.errors['images']"
                                            v-model="form.images"
                                            :existing_files="props.images"
                                            @imagesChanger="storeFiles"></dropzone-new>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Delete')||actions.includes('Update')">
            <button type="button"
                    v-if="actions.includes('Delete')"
                    class="btn btn-outline-danger mb-3 mb-sm-0" @click="deleteItem">Delete cabin promo</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-alt-secondary mb-3 mb-sm-0 ms-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-primary ms-sm-3" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
