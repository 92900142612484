<script setup>

import {computed, nextTick, ref} from "vue";
import { Head, useForm, Link } from '@inertiajs/vue3';

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";
import Tabs from "../../components/UI/Tabs.vue";
import Tab from "../../components/UI/Tab.vue";
import DropzoneNew from "../../components/UI/DropZoneNew.vue";
import SelectTagCustom from "../../components/UI/SelectTagCustom.vue";
import RadioButtonsYesNo from "../../components/UI/RadioButtonsYesNo.vue";
import SelectPhoneCode from "../../components/UI/SelectPhoneCode.vue";
import CheckboxButton from "../../components/UI/CheckboxButton.vue";

const props = defineProps({
    countries: Object,
    dialCodes: Object,
    mileagesList: Object,
    airportsList: Object,
    alliancesList: Object,
});

const form = useForm({
    title: '',
    iata: '',
    icao: '',
    country_id: '',
    callsign: '',
    mileages: [],
    alliance_id: '',
    airports: [],
    stock: '',
    international: '',
    skytrax: '',
    fleet_age: '',
    fleet_size: '',
    accidents: '',
    crashes: '',
    website: '',
    check_in: '',
    baggage: '',
    email: '',
    dial_code_id: '',
    phone: '',
    full_logo_main: [],
    full_logo_fallback: [],
    full_logo_use_fallback: false,
    oaf_logo_main: [],
    oaf_logo_fallback: [],
    oaf_logo_use_fallback: false,
    email_logo_main: [],
    email_logo_fallback: [],
    email_logo_use_fallback: false,
    exit: false,
    facts: [''],
})

const searchCountryString = ref('');
const searchDialString = ref('');
const searchAirportString = ref('');
const searchMileageString = ref('');

const searchCountry = (value) => {
    searchCountryString.value = value;
}

const searchDial = (value) => {
    searchDialString.value = value;
}

const searchAirport = (value) => {
    searchAirportString.value = value;
}

const searchMileage = (value) => {
    searchMileageString.value = value;
}

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    form[key] = option;
}

const storeFiles = (files, key) => {
    form[key] = files;
}

const handleChangeTag = (tags) => {
    form.error_title = tags;
}

const handleRadioButtonInput = (id, key) => {
    form[key] = id == 'true' ? true : false
}

const handleCheckboxButtonInput = (value, key) => {
    form[key] = value
}

const countriesList = computed(() => {
    return props.countries.filter(country => country.title.toLowerCase().includes(searchCountryString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
}) ;

const dialCodes = computed(() => {
    return props.dialCodes.filter(dialCode => {
            let dialCodeString = dialCode.code.toString();
            let searchDialStringWithoutPlus = searchDialString.value.replace('+', '');
            return dialCode.title.toLowerCase().includes(searchDialString.value.toLowerCase()) || dialCodeString.toLowerCase().includes(searchDialStringWithoutPlus.toLowerCase())
        }

    ).map((item) => {
        return {
            title: item.title,
            id: item.id,
            code: item.code,
            image: item.image
        }
    })
});

const airportsList = computed(() => {
    return props.airportsList.filter(airport => airport.title.toLowerCase().includes(searchAirportString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
}) ;

const mileagesList = computed(() => {
    return props.mileagesList.filter(aircraft => aircraft.title.toLowerCase().includes(searchMileageString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            country_id: data.country_id && data.country_id.id ? data.country_id.id : data.country_id,
            mileages: data.mileages.map(item => item.id),
            alliance_id: data.alliance_id && data.alliance_id.id ? data.alliance_id.id : data.alliance_id,
            dial_code_id: data.dial_code_id && data.dial_code_id.id ? data.dial_code_id.id : null,
            airports: data.airports.map(item => item.id),
            facts: data.facts.filter(item => item !== '')
        }))
        .post('/admin/airlines/store', {
            preserveScroll: true,
            onSuccess: () => form.reset(),
        })
}

const addInput = async () => {
    form.facts.push('');

    await nextTick()
    let inputs = document.querySelectorAll('.tab-facts .form-control');
    const lastInputIndex = form.facts.length - 1;

    if (inputs.length) {
        inputs[lastInputIndex].focus();
    }
}

</script>

<template>

    <Head title="Add airline" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <Link class="link-fx"
                          href="/admin/airlines">
                        Airlines
                    </Link>
                </li>
                <li class="breadcrumb-item">
                    Add airline
                </li>
            </ol>
        </template>

        <template v-slot:title>Airlines</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <Tabs>
                                <Tab title="Add airline" :selected="true" :hint="form.errors['title'] || form.errors.iata || form.errors.icao || form.errors.country_id">
                                    <div class="row g-12">
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple
                                                label="Airline name"
                                                name="title"
                                                required
                                                placeholder="Enter airline name"
                                                v-model="form.title"
                                                :hint="form.errors['title']"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="IATA"
                                                          required
                                                          name="iata"
                                                          placeholder="Enter IATA"
                                                          v-model="form.iata"
                                                          :hint="form.errors.iata"
                                                          uppercase
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="ICAO"
                                                          required
                                                          name="icao"
                                                          placeholder="Enter ICAO"
                                                          v-model="form.icao"
                                                          :hint="form.errors.icao"
                                                          uppercase
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="Country"
                                                           required
                                                           enableSearch
                                                           @select-option="(e)=>handleSelect(e,'country_id')"
                                                           :value="form.country_id"
                                                           :options="countriesList"
                                                           :hint="form.errors.country_id"
                                                           @search="searchCountry"
                                                           name="country_id"
                                                           placeholder="Select Country"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Call sign"
                                                          name="callsign"
                                                          placeholder="Enter call sign"
                                                          v-model="form.callsign"
                                                          :hint="form.errors.callsign"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4"></div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-tag-custom label="Mileage program"
                                                               @select-option="(e)=>handleSelect(e,'mileages')"
                                                               :value="form.mileages"
                                                               :options="mileagesList"
                                                               :hint="form.errors.mileages"
                                                               @search="searchMileage"
                                                               name="mileages"
                                                               placeholder="Select mileage program"
                                            ></select-tag-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="Alliance"
                                                           @select-option="(e)=>handleSelect(e,'alliance_id')"
                                                           :value="form.alliance_id"
                                                           :options="props.alliancesList"
                                                           :hint="form.errors.alliance_id"
                                                           name="alliance_id"
                                                           placeholder="Select alliance"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-tag-custom label="HUB"
                                                               enableSearch
                                                               @select-option="(e)=>handleSelect(e,'airports')"
                                                               :value="form.airports"
                                                               :options="airportsList"
                                                               :hint="form.errors.airports"
                                                               @search="searchAirport"
                                                               name="airports"
                                                               placeholder="Select HUB"
                                            ></select-tag-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Stock"
                                                          name="stock"
                                                          placeholder="Enter stock"
                                                          v-model="form.stock"
                                                          :hint="form.errors.stock"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <radio-buttons-yes-no
                                                label="International"
                                                name="international"
                                                @changeRadio="(e)=>handleRadioButtonInput(e,'international')"
                                                :hint="form.errors.international"
                                            />
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4"></div>
                                        <div class="col-12 col-sm-6 col-lg-2">
                                            <input-simple label="SkyTrax"
                                                          name="skytrax"
                                                          v-model="form.skytrax"
                                                          :hint="form.errors.skytrax"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-2">
                                            <input-simple label="Fleet size"
                                                          name="fleet_size"
                                                          v-model="form.fleet_size"
                                                          :hint="form.errors.fleet_size"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-2">
                                            <input-simple label="Fleet age"
                                                          name="fleet_age"
                                                          v-model="form.fleet_age"
                                                          :hint="form.errors.fleet_age"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-2">
                                            <input-simple label="Accidents"
                                                          name="accidents"
                                                          v-model="form.accidents"
                                                          :hint="form.errors.accidents"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-2">
                                            <input-simple label="Crashes"
                                                          name="crashes"
                                                          v-model="form.crashes"
                                                          :hint="form.errors.crashes"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Website"
                                                          name="website"
                                                          placeholder="Add airline website"
                                                          v-model="form.website"
                                                          :hint="form.errors.website"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Online Check-in"
                                                          name="check_in"
                                                          placeholder="Add check-in link"
                                                          v-model="form.check_in"
                                                          :hint="form.errors.check_in"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Baggage"
                                                          name="baggage"
                                                          placeholder="Add baggage link "
                                                          v-model="form.baggage"
                                                          :hint="form.errors.baggage"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Email"
                                                          name="email"
                                                          placeholder="Add airline email"
                                                          v-model="form.email"
                                                          :hint="form.errors.email"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <label class="form-label">Phone number</label>
                                            <div class="code__phone_block"
                                                 :class="{'has-error':form.errors.dial_code_id || form.errors.phone}"
                                            >
                                                <div class="d-flex align-items-center">
                                                    <select-phone-code
                                                        enableSearch
                                                        @select-option="(e)=>handleSelect(e,'dial_code_id')"
                                                        :value="form.dial_code_id"
                                                        :options="dialCodes"
                                                        @search="searchDial"
                                                        name="dial_code_id"
                                                        placeholder="&nbsp;"
                                                    >
                                                    </select-phone-code>
                                                    <input-simple
                                                        name="phone"
                                                        placeholder="XXXXXXXXXX"
                                                        v-model.number="form.phone"
                                                    ></input-simple>
                                                </div>
                                                <div class="form-hint" v-if="form.errors.dial_code_id">
                                                    {{ form.errors.dial_code_id }}
                                                </div>
                                                <div class="form-hint" v-if="form.errors.phone">
                                                    {{ form.errors.phone }}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Tab>
                                <Tab title="Logos" :hint="form.errors['full_logo_main'] || form.errors['full_logo_fallback'] || form.errors['oaf_logo_main'] || form.errors['oaf_logo_fallback'] || form.errors['email_logo_main'] || form.errors['email_logo_fallback']">
                                    <h5>Full logo</h5>
                                    <div class="row g-12 push">
                                        <div class="col-12 col-md-6">
                                            <dropzone-new
                                                label="Main (max 1 files)"
                                                name="full_logo_main"
                                                maxFiles="1"
                                                :hint="form.errors['full_logo_main']"
                                                v-model="form.full_logo_main"
                                                :existing_files="props.full_logo_main"
                                                @imagesChanger="(e)=>storeFiles(e,'full_logo_main')">
                                            </dropzone-new>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <dropzone-new
                                                label="Fallback (max 1 files)"
                                                name="full_logo_fallback"
                                                maxFiles="1"
                                                :hint="form.errors['full_logo_fallback']"
                                                v-model="form.full_logo_fallback"
                                                :existing_files="props.full_logo_fallback"
                                                @imagesChanger="(e)=>storeFiles(e,'full_logo_fallback')">
                                                <template #checkbox>
                                                    <checkbox-button
                                                        name="full_logo_use_fallback"
                                                        label="Use on websites"
                                                        v-model="form.full_logo_use_fallback"
                                                        @changeCheckbox="(e)=>handleCheckboxButtonInput(e,'full_logo_use_fallback')">
                                                    </checkbox-button>
                                                </template>
                                            </dropzone-new>
                                        </div>
                                    </div>

                                    <h5>OAF logo</h5>
                                    <div class="row g-12 push">
                                        <div class="col-12 col-md-6">
                                            <dropzone-new
                                                label="Main (max 1 files)"
                                                name="oaf_logo_main"
                                                maxFiles="1"
                                                :hint="form.errors['oaf_logo_main']"
                                                v-model="form.oaf_logo_main"
                                                :existing_files="props.oaf_logo_main"
                                                @imagesChanger="(e)=>storeFiles(e,'oaf_logo_main')">
                                            </dropzone-new>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <dropzone-new
                                                label="Fallback (max 1 files)"
                                                name="oaf_logo_fallback"
                                                maxFiles="1"
                                                :hint="form.errors['oaf_logo_fallback']"
                                                v-model="form.oaf_logo_fallback"
                                                :existing_files="props.oaf_logo_fallback"
                                                @imagesChanger="(e)=>storeFiles(e,'oaf_logo_fallback')">
                                                <template #checkbox>
                                                    <checkbox-button
                                                        name="oaf_logo_use_fallback"
                                                        label="Use on websites"
                                                        v-model="form.oaf_logo_use_fallback"
                                                        @changeCheckbox="(e)=>handleCheckboxButtonInput(e,'oaf_logo_use_fallback')">
                                                    </checkbox-button>
                                                </template>
                                            </dropzone-new>
                                        </div>
                                    </div>

                                    <h5>Email logo</h5>
                                    <div class="row g-12">
                                        <div class="col-12 col-md-6">
                                            <dropzone-new
                                                label="Main (max 1 files)"
                                                name="email_logo_main"
                                                maxFiles="1"
                                                :hint="form.errors['email_logo_main']"
                                                v-model="form.email_logo_main"
                                                :existing_files="props.email_logo_main"
                                                @imagesChanger="(e)=>storeFiles(e,'email_logo_main')">
                                            </dropzone-new>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <dropzone-new
                                                label="Fallback (max 1 files)"
                                                name="email_logo_fallback"
                                                maxFiles="1"
                                                :hint="form.errors['email_logo_fallback']"
                                                v-model="form.email_logo_fallback"
                                                :existing_files="props.email_logo_fallback"
                                                @imagesChanger="(e)=>storeFiles(e,'email_logo_fallback')">
                                                <template #checkbox>
                                                    <checkbox-button
                                                        name="email_logo_use_fallback"
                                                        label="Use on websites"
                                                        v-model="form.email_logo_use_fallback"
                                                        @changeCheckbox="(e)=>handleCheckboxButtonInput(e,'email_logo_use_fallback')">
                                                    </checkbox-button>
                                                </template>
                                            </dropzone-new>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab title="Facts">
                                    <div class="row g-12">
                                        <div
                                            class="col-12"
                                            :class="{'d-flex gap-4 align-items-end' : index !== 0}"
                                            v-for="(input, index) in form.facts"
                                            :key="index"
                                        >

                                            <input-simple
                                                :label="'Fact #'+ (index + 1)"
                                                name="facts"
                                                placeholder="Enter airline's fact"
                                                v-model="form.facts[index]"
                                                :hint="form.errors.facts"
                                            ></input-simple>

                                            <button class="btn btn-remove btn-alt-secondary mb-3" type="button"
                                                    v-if="index !== 0"
                                                    @click="form.facts.splice(index, 1)"
                                                >
                                                    <i class="fa fa-fw fa-times"></i>
                                            </button>
                                        </div>

                                        <div class="col-12">
                                            <button class="btn btn-sm p-0 text-primary" type="button"
                                                    @click="addInput"
                                            >
                                                <i class="fa fa-fw fa-plus"></i>
                                                Add another fact
                                            </button>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <button type="button" class="btn btn-alt-secondary mb-3 mb-sm-0 me-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button" class="btn btn-primary" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
