<script setup>

defineProps({
    name: {
        type: String,
        required: true
    },
    label: {
        type: String,
    },
    checked: {
        type: Boolean,
        default: false
    },
    option: {
        type: Boolean,
        default: null
    },
});

</script>

<template>
    <div class="form-check">
        <input
            class="form-check-input"
            type="checkbox"
            :id="name"
            :name="name"
            :checked="checked || option === true"
            @change="$emit('changeCheckbox', $event.target.checked)"
        >
        <label class="form-check-label"
               :for="name"
        >{{ label }}</label>
    </div>
</template>

<script>
    export default {
        name: "CheckboxButton",
    }
</script>

<style scoped>

</style>
