<script setup>

import { computed, ref } from 'vue';
import {Head, router, useForm} from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";
import TextareaSimple from "../../components/UI/TextareaSimple.vue";
import DropzoneNew from "../../components/UI/DropZoneNew.vue";
import Published from "../../components/UI/Published.vue";

const props = defineProps({
    resource: Object,
    cities: Object,
    placements: Object,
    sources: Object,
    images: Object,
    actions: {
      type: Array,
      default: () => []
    },
});

const ratingList = [
    { id: 1, title: '1' },
    { id: 2, title: '2' },
    { id: 3, title: '3' },
    { id: 4, title: '4' },
    { id: 5, title: '5' },
];

const resource = props.resource;
const imageList = props.images;

const form = useForm({
    customer_name: resource.customer_name,
    title: resource.title,
    rating: resource.rating,
    text: resource.text,
    placement_id: resource.placement,
    source_id: resource.source,
    city_from_id: resource.city_from,
    city_to_id: resource.city_to,
    image: [],
    published: resource.published,
    resource_id: resource.id,
    exit: false,
})

// const searchCountryString = ref('');

const handleInput = (id, key) => {
    form[key] = id;
}

const storeFiles = (files) => {
  form.image = files;
}
const handleCheckboxButtonInput = (value, key) => {
    form[key] = value
}

const searchCityFromString = ref('');
const searchCityToString = ref('');

const searchCityFrom = (value) => {
    searchCityFromString.value = value;
}

const searchCityTo = (value) => {
    searchCityToString.value = value;
}

const citiesFromList = computed(() => {
    return props.cities.filter(city => city.title.toLowerCase().includes(searchCityFromString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});
const citiesToList = computed(() => {
    return props.cities.filter(city => city.title.toLowerCase().includes(searchCityToString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

const placementsList = computed(() => {
  return props.placements.filter(placement => placement.title.toLowerCase()).map((item) => {
    return {
      title: item.title,
      id: item.id
    }
  })
});

const sourcesList = computed(() => {
  return props.sources.filter(source => source.title.toLowerCase()).map((item) => {
    return {
      title: item.title,
      id: item.id
    }
  })
});

const handleSelect = (option, key) => {
    form[key] = option;
}
const submitForm = (exit) => {
    form.exit = !!exit;
    form.errors['image'] = '';
    form
        .transform((data) => ({
            ...data,
            city_from_id: data.city_from_id && data.city_from_id.id ? data.city_from_id.id : data.city_from_id,
            city_to_id: data.city_to_id && data.city_to_id.id ? data.city_to_id.id : data.city_to_id,
            placement_id: data.placement_id && data.placement_id.id ? data.placement_id.id : data.placement_id,
            source_id: data.source_id && data.source_id.id ? data.source_id.id : data.source_id,
            rating: data.rating && data.rating.id ? data.rating.id : data.rating,
        }))
        .put('/admin/reviews/update/' + resource.id , {
            preserveScroll: true,
            // preserveState: false,
            onSuccess: () => {
                router.reload({ only: ['form'] }) // при сохранении без выхода обновляем данные в форме
            },
        })
}

const deleteItem = () => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/reviews/destroy/' + resource.id, {
            preserveScroll: true,
        })
    }
}

// const searchCountry = (value) => {
//     searchCountryString.value = value;
// }

// const countriesList = computed(() => {
//     return props.countries.filter(country => country.title.toLowerCase().includes(searchCountryString.value.toLowerCase())).map((item) => {
//         return {
//             title: item.title,
//             id: item.id
//         }
//     })
// }) ;

</script>

<template>

    <Head title="Add review" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/reviews">Reviews</a>
                </li>
                <li class="breadcrumb-item">
                    Add state
                </li>
            </ol>
        </template>

        <template v-slot:title>Reviews</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Add review</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Customer name"
                                            name="customer_name"
                                            required
                                            placeholder="Enter customer name"
                                            v-model="form.customer_name"
                                            :hint="form.errors.customer_name"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <input-simple
                                            label="Review title"
                                            name="title"
                                            placeholder="Enter review title"
                                            v-model="form.title"
                                            :hint="form.errors.title"
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <select-custom
                                            label="Rating"
                                            @select-option="(e)=>handleSelect(e,'rating')"
                                            :value="form.rating"
                                            :options="ratingList"
                                            :hint="form.errors.rating"
                                            name="rating"
                                            required
                                            placeholder="Select rating"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12">
                                        <textarea-simple
                                            label="Review text"
                                            name="text"
                                            required
                                            placeholder="Enter review text"
                                            v-model="form.text"
                                            :hint="form.errors.text"
                                        ></textarea-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <select-custom
                                            label="Placement"
                                            required
                                            @select-option="(e)=>handleSelect(e,'placement_id')"
                                            :value="form.placement_id"
                                            :options="placementsList"
                                            :hint="form.errors.placement_id"
                                            name="placement_id"
                                            placeholder="Select placement"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <select-custom
                                            label="Review source"
                                            @select-option="(e)=>handleSelect(e,'source_id')"
                                            :value="form.source_id"
                                            :options="sourcesList"
                                            :hint="form.errors.source_id"
                                            name="source_id"
                                            placeholder="Select review source"
                                        ></select-custom>
                                    </div>
                                </div>
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <select-custom label="From"
                                                       enableSearch
                                                       @select-option="(e)=>handleSelect(e,'city_from_id')"
                                                       :value="form.city_from_id"
                                                       :options="citiesFromList"
                                                       :hint="form.errors.city_from_id"
                                                       @search="searchCityFrom"
                                                       name="city_from_id"
                                                       placeholder="Select from city"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <select-custom label="To"
                                                       enableSearch
                                                       @select-option="(e)=>handleSelect(e,'city_to_id')"
                                                       :value="form.city_to_id"
                                                       :options="citiesToList"
                                                       :hint="form.errors.city_to_id"
                                                       @search="searchCityTo"
                                                       name="city_to_id"
                                                       placeholder="Select to city"
                                        ></select-custom>
                                    </div>

                                    <div class="col-12">
                                        <dropzone-new
                                            label="Customer picture (max 1 files)"
                                            name="images"
                                            maxFiles="1"
                                            :hint="form.errors['image']"
                                            v-model="form.image"
                                            @imagesChanger="storeFiles"
                                            :existing_files="props.images"
                                        ></dropzone-new>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <published
                                            name="published"
                                            :option="resource.published"
                                            @changeCheckbox="(e)=>handleCheckboxButtonInput(e,'published')"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Delete')||actions.includes('Update')">
            <button type="button"
                    v-if="actions.includes('Delete')"
                    class="btn btn-outline-danger mb-3 mb-sm-0" @click="deleteItem">Delete state</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-alt-secondary mb-3 mb-sm-0 ms-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-primary ms-sm-3" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
