<template>
    <div class="form-group"
         :class="hint ? 'has-error' : '' "
    >
        <label
            v-if="label"
            class="form-label"
        >
            {{ label}}
            <span v-if="required" class="form-required text-danger">*</span>
        </label>

        <div class="d-flex gap-4">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    :id="name + '-yes'"
                    :name="name"
                    value="true"
                    :checked="option === true"
                    @change="$emit('changeRadio', $event.target.value)"
                >
                <label class="form-check-label"
                       :for="name + '-yes'"
                >Yes</label>
            </div>
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    :id="name + '-no'"
                    :name="name"
                    value="false"
                    :checked="option === false"
                    @change="$emit('changeRadio', $event.target.value)"
                >
                <label class="form-check-label"
                       :for="name + '-no'"
                >No</label>
            </div>
        </div>

        <div class="form-hint" v-if="hint">
            {{ hint }}
        </div>
    </div>

</template>

<script>
export default {
    name: "RadioButtonsYesNo",
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
        },
        hint: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        option: {
            type: Boolean,
            default: null
        },
    },
}
</script>

<style scoped>
    .form-check {
        position: relative;
        padding: 0;
        margin: 0;
    }

    .form-check-input {
        position: absolute;
        top: 8px;
        left: 0;
        margin: 0;
    }
    .form-check-label {
        padding: 7px 0 7px 1.75rem;
    }


</style>
