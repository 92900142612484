<script setup>

import { computed, ref } from 'vue';
import { Head, useForm, Link } from '@inertiajs/vue3'
import axios from "axios";

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";
import InputTags from "../../components/UI/InputTags.vue";
import Tabs from "../../components/UI/Tabs.vue";
import Tab from "../../components/UI/Tab.vue";

const props = defineProps({
    cityType: Object,
    countries: Object,
    states: Object,
    languages: Object,
});

const cityTypeList = props.cityType;
const statesList = ref([]);
const timezonesList = ref([]);
const searchCountryString = ref('');

const form = useForm({
    title: {},
    iata: '',
    city_type_id: '',
    country_id: '',
    state_exist: true,
    state_id: '',
    timezone_id: '',
    latitude: '',
    longitude: '',
    altitude: '',
    metro_area_name: '',
    metro_area_code: '',
    population: '',
    error_title: [],
    exit: false,
})

const findLanguage = (code, array) => {
    return array.find((item) => item.code == code).title;
}

const selectRequest = (key, option) => {
    if (key === 'country_id') {
        statesList.value = [];
        timezonesList.value = [];
        form.state_id = '';
        form.timezone_id = '';
        axios
            .post('/admin/states/search/list', {
                country_id: option.id,
            })
            .then((response) => {
                if (response.data.data.length > 0) {
                    statesList.value = response.data.data;
                    form.state_exist = true;
                } else {
                    form.state_exist = false;
                }
            })

        axios
            .post('/admin/timezones/search/list', {
                country_id: option.id,
            })
            .then((response) => {
                if (response.data.data.length > 0) timezonesList.value = response.data.data;
            })
    }
}

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    form[key] = option;
    selectRequest(key, option);
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            city_type_id: data.city_type_id && data.city_type_id.id ? data.city_type_id.id : data.city_type_id,
            country_id: data.country_id && data.country_id.id ? data.country_id.id : data.country_id,
            state_id: data.state_id && data.state_id.id ? data.state_id.id : data.state_id,
            timezone_id: data.timezone_id && data.timezone_id.id ? data.timezone_id.id : data.timezone_id,
        }))
        .post('/admin/cities/store', {
            preserveScroll: true,
            onSuccess: () => form.reset(),
    })
}

const handleChangeTag = (tags) => {
    form.error_title = tags;
}

const searchCountry = (value) => {
    searchCountryString.value = value;
}

const isCountrySelected = computed(() => !!form.country_id && statesList.value.length > 0)

const isStateSelected = computed(() => !!form.country_id && timezonesList.value.length > 0)
const countriesList = computed(() => {
    return props.countries.filter(country => country.title.toLowerCase().includes(searchCountryString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
}) ;

</script>

<template>

    <Head title="Add city" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <Link class="link-fx"
                          href="/admin/cities">
                        Cities
                    </Link>
                </li>
                <li class="breadcrumb-item">
                    Add city
                </li>
            </ol>
        </template>

        <template v-slot:title>Cities</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <Tabs>
                                <Tab title="Add city" :selected="true">
                                    <div class="row g-12">
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple
                                                label="City name"
                                                name="title[en]"
                                                required
                                                placeholder="Enter city name"
                                                v-model="form.title.en"
                                                :hint="form.errors['title.en']"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="City IATA"
                                                          required
                                                          name="iata"
                                                          placeholder="Enter IATA"
                                                          v-model="form.iata"
                                                          :hint="form.errors.iata"
                                                          uppercase
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="City type"
                                                           @select-option="(e)=>handleSelect(e,'city_type_id')"
                                                           :value="form.city_type_id"
                                                           :options="cityTypeList"
                                                           :hint="form.errors.city_type_id"
                                                           name="city_type_id"
                                                           placeholder="Select Country"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="Country"
                                                           required
                                                           enableSearch
                                                           @select-option="(e)=>handleSelect(e,'country_id')"
                                                           :value="form.country_id"
                                                           :options="countriesList"
                                                           :hint="form.errors.country_id"

                                                           @search="searchCountry"
                                                           name="country_id"
                                                           placeholder="Select Country"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="State"
                                                           :required="form.state_exist"
                                                           :disabled="!isCountrySelected"
                                                           @select-option="(e)=>handleSelect(e,'state_id')"
                                                           :value="form.state_id"
                                                           :options="statesList"
                                                           :hint="form.errors.state_id"
                                                           name="state_id"
                                                           placeholder="Select State"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <select-custom label="Timezone"
                                                           :disabled="!isStateSelected"
                                                           @select-option="(e)=>handleSelect(e,'timezone_id')"
                                                           :value="form.timezone_id"
                                                           :options="timezonesList"
                                                           :hint="form.errors.timezone_id"
                                                           name="timezone_id"
                                                           placeholder="Select timezone"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Latitude"
                                                          name="latitude"
                                                          placeholder="Enter latitude"
                                                          v-model="form.latitude"
                                                          :hint="form.errors.latitude"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Longitude"
                                                          name="longitude"
                                                          placeholder="Enter longitude"
                                                          v-model="form.longitude"
                                                          :hint="form.errors.longitude"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Altitude, m"
                                                          name="altitude"
                                                          placeholder="Enter altitude"
                                                          v-model.number="form.altitude"
                                                          :hint="form.errors.altitude"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Metro area name"
                                                          name="metro_area_name"
                                                          placeholder="Enter metro area name"
                                                          v-model="form.metro_area_name"
                                                          :hint="form.errors.metro_area_name"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Metro area code"
                                                          name="metro_area_code"
                                                          placeholder="Enter metro area code"
                                                          v-model="form.metro_area_code"
                                                          :hint="form.errors.metro_area_code"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-lg-4">
                                            <input-simple label="Population"
                                                          name="population"
                                                          placeholder="Enter population"
                                                          v-model="form.population"
                                                          :hint="form.errors.population"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12">
                                            <input-tags label="Syntax Errors"
                                                        name="error_title"
                                                        :hint="form.errors.error_title"
                                                        v-model="form.error_title"
                                            />
                                        </div>


<!--                                        <div class="col-12 col-sm-6 col-lg-4">-->
<!--                                            <select-custom label="State"-->
<!--                                                           required-->
<!--                                                           @select-option="(e)=>handleSelect(e,'state_id')"-->
<!--                                                           :value="form.state_id"-->
<!--                                                           :options="statesList"-->
<!--                                                           :hint="form.errors.state_id"-->
<!--                                                           name="state_id"-->
<!--                                                           placeholder="Select State"-->
<!--                                            >-->
<!--                                                <template #dropDown="{ options, selectHandle }">-->
<!--                                                    <div style="padding: 1em" v-for="option in options" @click="selectHandle(option)"> {{ option.title }}</div>-->
<!--                                                </template>-->
<!--                                            </select-custom>-->
<!--                                        </div>-->


                                    </div>
                                </Tab>
                                <Tab title="Languages">
                                    <div class="row g-12">
                                        <template v-for="item in languages">
                                            <div class="col-12 col-sm-6" v-if="(item.code !== 'en')">
                                                <input-simple
                                                    label="City name"
                                                    :lang="item.title"
                                                    :name="'title['+[item.code]+']'"
                                                    placeholder="Enter city name"
                                                    v-model="form.title[item.code]"
                                                ></input-simple>
                                            </div>
                                        </template>
                                    </div>
                                </Tab>
                            </Tabs>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons>
            <button type="button" class="btn btn-alt-secondary mb-3 mb-sm-0 me-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button" class="btn btn-primary" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
