<template>
    <div class="form-group"
        :class="hint ? 'has-error' : '' "
    >
        <label
            v-if="label"
            class="form-label"
            :class="disabled ? 'disabled' : ''"
        >
            {{ label }}
            <span v-if="required" class="form-required text-danger">*</span>
        </label>

        <vue3-tags-input :tags="modelValue"
                         placeholder="Add tags separated by comma/space"
                         @on-tags-changed="handleChangeTag"
                         @on-select="handleSelectedTag"
                         :select="true"
                         :select-items="localSelectData"

        >
            <template #item="{ tag, index }">
                {{ tag.text ?? tag }}
            </template>
            <template #no-data>
                No Data
            </template>
            <template #select-item="tag">
                {{ tag.text }}
            </template>
        </vue3-tags-input>

        <div class="form-hint" v-if="hint">
            {{ hint }}
        </div>
    </div>
</template>

<script>
import Vue3TagsInput from 'vue3-tags-input';

export default {
    name: "InputTagsSelect",
    components: {
        Vue3TagsInput
    },
    props: {
        selectData: {
            type: Array,
            default: [{text: 'OW'}, {text: 'RT'}],
        },
        type: {
            type: String,
            default: "text",
        },
        label: {
            type: String,
        },
        name: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        hint: {
            type: String,
        },
        uppercase: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        maxlength: {
            type: Number,
            default: 255,
        },
        modelValue: {
            type: Array,
        },
    },
    data() {
        return {
            localSelectData: this.selectData,
        }
    },
    methods: {
        handleChangeTag(tags) {
            // this.tags = tags;

            let tags_array = []

            tags.forEach((tag) => {
                // console.log('tag: ', tag)
                tags_array.push(tag.toUpperCase());
            })
            this.$emit('update:modelValue', tags_array);
        },
        handleSelectedTag(tag) {
            let tags_array = this.modelValue;
            tags_array.push(tag.text);
            this.$emit('update:modelValue', tags_array);
            console.log('before', this.localSelectData);
            this.localSelectData = this.localSelectData.filter(t => t.text !== tag.text);
            console.log('ater', this.localSelectData);

            },

    },
    watch: {
        modelValue: {
            deep: true,
            handler(newVal) {
                const selectedTags = newVal.map(t => ({ text: t }));
                const availableTags = this.selectData.filter(t => !selectedTags.find(st => st.text === t.text));
                this.localSelectData = [...availableTags];
            }
        }
    },
    mounted() {
        const selectedTags = this.modelValue.map(t => ({ text: t }));
        const availableTags = this.selectData.filter(t => !selectedTags.find(st => st.text === t.text));
        this.localSelectData = [...availableTags];
    }
}
</script>

<style lang="sass">
.form-group
    .v3ti
        border: 1px solid var(--gray-bg-gray)
        .v3ti-content
            padding: 0 7px
        .v3ti-tag
            height: 24px
            margin: 6px 5px
            font-size: 12px
            line-height: 16px
            border-radius: 4px
            padding: 4px 2px 4px 8px
            background-color: var(--primary-bg-primary-dark)
            .v3ti-remove-tag
                display: block
                opacity: 1
                width: 20px
                height: 20px
                padding: 0
                background-repeat: no-repeat
                background-position: center center
                background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cpath%20d%3D%22M9.3%208.98c.23.28.23.56%200%20.85-.28.23-.57.23-.85%200L5.5%206.86%202.52%209.83c-.28.23-.56.23-.84%200-.24-.29-.24-.57%200-.85L4.65%206%201.67%203.02c-.23-.28-.23-.56%200-.85.29-.23.57-.23.85%200l2.98%203L8.47%202.2c.29-.23.57-.23.85%200%20.24.28.24.56%200%20.85L6.35%206%209.3%208.98Z%22%20fill%3D%22%23fff%22%2F%3E%0A%3C%2Fsvg%3E%0A")
                &:before
                    display: none
        .v3ti-new-tag
            margin: 6px 3px
            height: 24px
    &.has-error
        .v3ti
            border-color: var(--contextual-bg-danger)

    .v3ti--focus
        border-color: var(--primary-bg-primary)
        box-shadow: none
</style>
