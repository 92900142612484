<template>
    <div class="form-group "
         :class="hint ? 'has-error' : '' "
    >
        <label
            v-if="label"
            :for="name"
            class="form-label"
            :class="{disabled: disabled, required: required}"
        >
            {{ label }}
            <span v-if="required" class="form-required text-danger">*</span>
        </label>

        <div class="custom-select">
            <div class="form-select form-select-code"
                 :class="{disabled: disabled, 'has-value': getSelectedValue}"
                 :name="name"
                 type="text"
                 :id="name"
                 @click.prevent="toggleDropdown"
                 readonly
            >
                <img :src="getSelectedImage" alt="" v-if="getSelectedImage">
                {{ getSelectedValue ? getSelectedValue : placeholder }}
            </div>

            <div class="custom-select-dropdown"
                 v-if="isDropdownOpen"
                 :class="getClassList" ref="dropdownMenu"
            >
                <div class="custom-select-dropdown-header" v-if="enableSearch">
                    <input v-focus v-model="searchString" :name="'search_' +name" type="text" class="form-control" @input="updateSearchInput" placeholder="Search...">
                </div>
                <template v-if="!$slots?.dropDown">
                    <div class="custom-select-dropdown-list" v-if="options.length > 0">
                        <div class="custom-select-dropdown-item with-code"
                             v-for="option in options"
                             @click="() => selectOption( option )"
                             :class="{ active: isSelected( option ) }"
                        >
                            <img :src="option.image" alt="">
                            {{ option.title }}
                            <span class="item-code">{{ option.code }}</span>
                        </div>
                    </div>
                    <div class="custom-select-dropdown-empty-list" v-else>
                        Nothing found
                    </div>
                </template>
                <slot name="dropDown" :options="options" :selectHandle="selectOption"></slot>
            </div>
        </div>

        <div class="form-hint" v-if="hint">
            {{ hint }}
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue'
export default {
    name: "SelectPhoneCode",
    props: {
        options: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
        },
        name: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        hint: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
        },
        enableSearch: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Object, String, Number],
        },
    },
    computed: {
        getSelectedImage() {
            return this.value?.image ?? null;
        },
        getSelectedValue() {
            // if (typeof this.value === 'string' || typeof this.value === 'number') return this.value;
            // if (typeof this.value?.title === 'string' || typeof this.value?.title === 'number') {
            //     return this.value?.code;
            // } else {
            //     return this.value?.title?.en ?? "value is null";
            // }

            return this.value?.code;

            // if (option?.id) {
            //     return this.selectedOption?.id == option?.id
            // } else {
            //     return this.selectedOption == option;
            // }

        },
        getClassList() {
            return {
                'with-search': this.enableSearch,
                'dropdown-bottom': this.isDropdownBelow,
                'dropdown-top': !this.isDropdownBelow
            }
        }
    },
    data() {
        return {
            isDropdownOpen: false,
            selectedOption: null,
            isDropdownBelow: true,
            searchString: '',
        };
    },
    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        updateSearchInput(event) {
            this.$emit('search', event.target.value);
        },
        selectOption(option) {
            // console.log('selectOption111: ', option)
            this.$emit('select-option', option);
            this.isDropdownOpen = !this.isDropdownOpen;
            return this.selectedOption = option;
        },
        toggleDropdown() {

            const thisElmBound = this.$el.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            const thisElmY = thisElmBound.y;
            const thisElmHeight = thisElmBound.height;

            // console.log('thisElmY: ', thisElmY)
            // console.log('thisElmHeight: ', thisElmHeight)
            // console.log('this.options.length: ', this.options.length)

            const searchHeight = this.enableSearch ? 62 : 8;
            const dropdownHeight = this.options.length >= 8 ? 8 * 36 + searchHeight + 8 : this.options.length * 36 + searchHeight + 8;

            thisElmY + thisElmHeight + dropdownHeight > windowHeight - 8 ? this.isDropdownBelow = false : this.isDropdownBelow = true;
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        isSelected(option) {
            if (option?.id) {
                return this.selectedOption?.id == option?.id
            } else {
                return this.selectedOption == option;
            }
        },
        getOptionTitle(option) {
            if (option?.id) {
                return option.title?.en ?? option.title
            } else {
                return option
            }
        }

    },
    beforeMount() {
        if (this.value) {
            this.selectedOption = this.value;
        }
    },
    mounted() {
        document.addEventListener('click', (event) => {
            if (!this.$el.contains(event.target)) {
                this.isDropdownOpen = false;
            }
        });
    },
    beforeDestroy() {
        document.removeEventListener('click', () => {
            this.isDropdownOpen = false;
        });
    }
}




</script>

<style scoped lang="sass">


</style>
