<script setup>

import { Head, router, useForm } from '@inertiajs/vue3'

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";

const props = defineProps({
    codeTypes: Object,
    GDSList: Object,
    resource: Object,
    actions: {
        type: Array,
        default: () => []
    },
});

const resource = props.resource;

const gdsList = props.GDSList;

const form = useForm({
    gds_id: resource.gds_id,
    code_type: resource.code_type,
    code: resource.code,
    description: resource.description,
    exit: false,
})

const codeTypesList = props.codeTypes;

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    form[key] = option;
}

const submitForm = (exit) => {
    form.exit = !!exit;
    form
        .transform((data) => ({
            ...data,
            gds_id: data.gds_id && data.gds_id.id ? data.gds_id.id : data.gds_id,
            code_type: data.code_type && data.code_type.id ? data.code_type.id : data.code_type,
        }))
        .put('/admin/statuses/update/' + resource.id , {
            preserveScroll: true,
            // preserveState: false,
            onSuccess: () => {
                router.reload({ only: ['form'] }) // при сохранении без выходя обновляем данные в форме
            },
        })
}

const deleteItem = () => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/statuses/destroy/' + resource.id, {
            preserveScroll: true,
        })
    }
}

</script>

<template>

    <Head title="Edit status" />

    <Layout>

        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <a class="link-fx" href="/admin/statuses">Statuses</a>
                </li>
                <li class="breadcrumb-item">
                    Edit status
                </li>
            </ol>
        </template>

        <template v-slot:title>Statuses</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-8">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Edit status</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <select-custom label="GDS"
                                                       required
                                                       @select-option="(e)=>handleSelect(e,'gds_id')"
                                                       :value="form.gds_id"
                                                       :options="gdsList"
                                                       :hint="form.errors.gds_id"
                                                       name="gds_id"
                                                       placeholder="Select GDS"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <select-custom label="Code type"
                                                       required
                                                       @select-option="(e)=>handleSelect(e,'code_type')"
                                                       :value="form.code_type"
                                                       :options="codeTypesList"
                                                       :hint="form.errors.code_type"
                                                       name="code_type"
                                                       placeholder="Select code type"
                                        ></select-custom>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <input-simple
                                            label="Code"
                                            name="code"
                                            required
                                            placeholder="Enter status code"
                                            v-model="form.code"
                                            :hint="form.errors.code"
                                            uppercase
                                        ></input-simple>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-3">
                                        <input-simple
                                            label="Description"
                                            name="description"
                                            required
                                            placeholder="Enter description"
                                            v-model="form.description"
                                            :hint="form.errors.description"
                                        ></input-simple>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Delete')||actions.includes('Update')">
            <button type="button"
                    v-if="actions.includes('Delete')"
                    class="btn btn-outline-danger mb-3 mb-sm-0" @click="deleteItem">Delete status</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-alt-secondary mb-3 mb-sm-0 ms-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-primary ms-sm-3" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
